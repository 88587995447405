import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import * as constants from 'common/constants';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import styles from './styles';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import ReactTimeout from "react-timeout";
import DB from "common/DB";
import DBParams from "common/DBParams";
import logo_facebook from 'images/logo_facebook.png';
import logo_instagram from 'images/logo_instagram.png';
import logo_twitter from 'images/logo_twitter.png';
import logo_website from 'images/logo_website.png';
import {db} from "common/firebase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import * as utils from "common/utils";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";

const logos = {
    facebook: logo_facebook,
    instagram: logo_instagram,
    twitter: logo_twitter,
    website: logo_website,
};

class OnePerson extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            cardExpanded: false,
            anchorElement: null,
            entity: null,
            entityStatusChangeInProgress: false,
            blockDialogOpen: false,
            blockReason: '',
            clearDialogOpen: false,
            propsToBeCleared: [],
        };
    }

    get isBlockDialogOpen() {
        return utils.notNullOrUndefined(this.state.entity) && this.state.blockDialogOpen;
    }

    get isClearDialogOpen() {
        return utils.notNullOrUndefined(this.state.entity) && this.state.clearDialogOpen;
    }

    componentDidMount() {
        this.fetchEntity()
            .then(async () => await this.setState({loading: false}));
    }

    fetchEntity = async () => {
        await DB.get('persons', new DBParams(this.props.entityId))
            .then(async result => {
                await this.setState({entity: result});
            })
    };

    handleExpandClick = () => {
        this.setState({cardExpanded: !this.state.cardExpanded});
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    handleOpenSocialMediaLink = (URL, type) => () => {
        let dURL = URL;
        switch (type) {
            case constants.SOCIAL_MEDIA_TYPE_FACEBOOK :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_FACEBOOK_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_INSTAGRAM :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_INSTAGRAM_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_TWITTER :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_TWITTER_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_WEBSITE :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_WEBSITE_BASE_URL}${URL}`;
                break;
            default:
                break;
        }
        window.open(dURL, '_blank');
    };

    handleBlockEntity = () => {
        this.handleOpenBlockDialog();
    }

    handleCloseBlockDialog = () => {
        this.handleResolveReport();
        this.setState({blockDialogOpen: false});
    }

    handleOpenBlockDialog = () => {
        this.setState({blockDialogOpen: true});
    }

    handleConfirmBlock = async () => {
        this.setState({loading: true});
        await Promise.all(
            (await db.collection('stories')
                .where('author.id', '==', this.props.entityId)
                .where('status', '==', constants.ENTITY_STATUS_ACTIVE)
                .get())
                .docs
                .map(doc => {
                    doc.ref.set({author: {status: constants.PERSON_STATUS_BLOCKED}}, {merge: true})
                    return doc.id;
                })
        );
        await DB.update(`persons`, new DBParams(this.props.entityId, {
            status: constants.ENTITY_STATUS_BLOCKED,
            blockReason: this.state.blockReason,
        }))
        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    }

    handleUnblockEntity = async () => {
        this.setState({loading: true});
        await Promise.all(
            (await db.collection('stories')
                .where('author.id', '==', this.props.entityId)
                .get()).docs.map(doc => {
                doc.ref.set({author: {status: constants.ENTITY_STATUS_ACTIVE}}, {merge: true})
                return doc.id;
            })
        );
        await DB.update(`persons`, new DBParams(this.props.entityId, {
            status: constants.ENTITY_STATUS_ACTIVE,
            blockReason: null,
        }))
        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    };

    handleResolveReport = () => {
        if (this.props.handleResolveReport !== null && this.props.handleResolveReport !== undefined) {
            this.props.handleResolveReport();
        }
    }

    handleChange = event => {
        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleClear = propName => async () => {
        let propsToBeClearedMap = {
            username: [
                'username',
                'usernameNormalized',
            ],
            fullname: [
                'fullname',
                'fullnameNormalized',
            ],
            bio: [
                'bio',
            ],
            socialMedias: [
                'socialMedias'
            ],
            avatar: [
                'avatar',
            ]
        }
        await this.setState({propsToBeCleared: propsToBeClearedMap[propName]})
        this.handleOpenClearDialog();
    };

    handleCloseClearDialog = () => {
        this.setState({clearDialogOpen: false});
    }

    handleOpenClearDialog = () => {
        this.setState({clearDialogOpen: true});
    }

    handleConfirmClear = async () => {

        let propsToUpdate = {};
        this.state.propsToBeCleared.forEach(prop => {
            propsToUpdate[prop] = null;
        });
        await DB.update(`persons`, new DBParams(this.props.entityId, propsToUpdate));
        await this.fetchEntity();
        this.handleCloseClearDialog();
    }

    render() {

        const {classes, origin} = this.props;
        const {loading, cardExpanded, anchorElement, entity, entityStatusChangeInProgress, blockReason} = this.state;

        return (
            <React.Fragment>
                {loading || !entity ? <Loader/> : (
                    <React.Fragment>
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="Recipe" className={classes.avatar}>
                                        {entity.fullname && entity.fullname.charAt(0)}
                                    </Avatar>
                                }
                                action={
                                    <Button
                                        aria-owns={entity ? 'simple-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleOpenEntityMenu}
                                    >
                                        <MoreVert/>
                                    </Button>
                                }
                                title={entity.fullname && entity.fullname}
                                subheader={`${entity.username ? entity.username : ''} (${entity.accountDetails ? entity.accountDetails.accountType : ''})`}
                            />
                            {entity.avatar &&
                            <div className={classes.profilePhotoWrapper}>
                                <CardMedia
                                    className={clsx(classes.media, classes.avatarImage)}
                                    image={entity.avatar.url}
                                    title="Profile Photo"
                                >
                                    <Tooltip title="Clear Profile Photo" aria-label="clear_profile_photo">
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="outlined"
                                            className={clsx(classes.clearButton, classes.clearProfilePhotoButton)}
                                            onClick={this.handleClear('avatar')}
                                        >
                                            Clear
                                        </Button>
                                    </Tooltip>
                                </CardMedia>
                            </div>
                            }
                            <CardContent>
                                {entity.bio &&
                                <React.Fragment>
                                    <Typography variant={'h6'}>Biography</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {entity.bio}
                                        <Tooltip title="Clear Biography" aria-label="clear_biography">
                                            <Button
                                                color="secondary"
                                                size="small"
                                                variant="outlined"
                                                className={classes.clearButton}
                                                onClick={this.handleClear('bio')}
                                            >
                                                Clear
                                            </Button>
                                        </Tooltip>
                                    </Typography>
                                </React.Fragment>}
                            </CardContent>
                            <CardActions>

                                {entity.status === constants.PERSON_STATUS_BLOCKED &&
                                <Chip
                                    avatar={<Avatar><BlockIcon/></Avatar>}
                                    label="Blocked"
                                    color="secondary"
                                    className={classes.chip}
                                />
                                }
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: cardExpanded,
                                    })}
                                    onClick={this.handleExpandClick}
                                    aria-expanded={cardExpanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </CardActions>
                            <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {entity.username &&
                                    <React.Fragment>
                                        <Typography paragraph>
                                            Username: <span className={classes.propertyValue}>{entity.username}</span>
                                            <Tooltip title="Clear Username" aria-label="clear_username">
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.clearButton}
                                                    onClick={this.handleClear('username')}
                                                >
                                                    Clear
                                                </Button>
                                            </Tooltip>
                                        </Typography>
                                    </React.Fragment>}
                                    {entity.fullname &&
                                    <React.Fragment>
                                        <Typography paragraph>
                                            Full Name: <span className={classes.propertyValue}>{entity.fullname}</span>
                                            <Tooltip title="Clear Full Name" aria-label="clear_fullname">
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.clearButton}
                                                    onClick={this.handleClear('fullname')}
                                                >
                                                    Clear
                                                </Button>
                                            </Tooltip>
                                        </Typography>
                                    </React.Fragment>}
                                    {entity.mobile && <Typography paragraph>
                                        Mobile: <span className={classes.propertyValue}>{entity.mobile}</span>
                                    </Typography>}
                                    {entity.storyRange && <Typography paragraph>
                                        Story Range: <span className={classes.propertyValue}>{entity.storyRange}</span>
                                    </Typography>}
                                </CardContent>
                                {entity.socialMedias && (
                                    <CardContent>
                                        <Typography variant={'h6'}>
                                            Social Medias
                                            <Tooltip title="Clear ALL Social Media" aria-label="clear_social_media">
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    variant="outlined"
                                                    className={classes.clearButton}
                                                    onClick={this.handleClear('socialMedias')}
                                                >
                                                    Clear
                                                </Button>
                                            </Tooltip>
                                        </Typography>
                                        {entity.socialMedias.map((socialMedia, index) => (
                                            <IconButton
                                                className={clsx(classes[`socialMediaIconButton`])}
                                                key={index}
                                                onClick={this.handleOpenSocialMediaLink(socialMedia.link.url, socialMedia.type)}
                                            >
                                                <img src={logos[socialMedia.type]} alt={socialMedia.type}/>
                                            </IconButton>
                                        ))}
                                    </CardContent>
                                )}
                                {entity.stories && (
                                    <CardContent>
                                        <Typography variant={'h6'}>Stories</Typography>
                                        {entity.stories.map((story, index) => (
                                            <Typography key={index}>{story.message}</Typography>
                                        ))}
                                    </CardContent>
                                )}
                            </Collapse>
                        </Card>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REGULAR && !entityStatusChangeInProgress && entity && (
                                <MenuItem
                                    onClick={entity.status === constants.LOCATION_STATUS_ACTIVE ? this.handleBlockEntity : this.handleUnblockEntity}
                                >
                                    {entity.status === constants.LOCATION_STATUS_ACTIVE ? 'Block' : 'Unblock'}
                                </MenuItem>
                            )}
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REPORT && !entityStatusChangeInProgress && entity && [
                                (<MenuItem key={'blockKey'} onClick={this.handleBlockEntity}>Block</MenuItem>),
                                (<MenuItem key={'sanctionKey'}
                                           onClick={this.props.handleSanctionEntity}>Sanction</MenuItem>),
                            ]}
                        </Menu>
                        <Dialog
                            open={this.isBlockDialogOpen}
                            onClose={this.handleCloseBlockDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Do you want to block this person?
                                </DialogContentText>
                                <TextField
                                    value={blockReason}
                                    margin="dense"
                                    id="blockReason"
                                    name="blockReason"
                                    label="Block Reason"
                                    rowsMax={5}
                                    rows={5}
                                    multiline={true}
                                    type="text"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={entityStatusChangeInProgress}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseBlockDialog} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={this.handleConfirmBlock} color="primary" autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.isClearDialogOpen}
                            onClose={this.handleCloseClearDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Do you want to clear this info?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseClearDialog} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={this.handleConfirmClear} color="primary" autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={this.props.handleCloseEntityDialog}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

OnePerson.propTypes = {
    classes: PropTypes.object.isRequired,
    entityId: PropTypes.string.isRequired,
    handleCloseEntityDialog: PropTypes.func.isRequired,
    handleResolveReport: PropTypes.func,
    handleSanctionEntity: PropTypes.func,
    origin: PropTypes.string.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(OnePerson))