import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import * as constants from 'common/constants';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import styles from './styles';
import CardActions from "@material-ui/core/CardActions";
import BlockIcon from '@material-ui/icons/Block';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import ReactTimeout from "react-timeout";
import DB from "common/DB";
import DBParams from "common/DBParams";
import {db} from "common/firebase";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

class OneActivity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            cardExpanded: false,
            anchorElement: null,
            entity: null,
            entityStatusChangeInProgress: false,
        };
    }

    componentDidMount() {
        this.fetchEntity()
            .then(async () => await this.setState({loading: false}));
    }

    fetchEntity = async () => {
        await DB.get('activities', new DBParams(this.props.entityId))
            .then(async result => {
                await this.setState({entity: result});
            })
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    handleOpenSocialMediaLink = url => () => {
        window.open(url, '_blank');
    };

    handleBlockEntity = async () => {
        this.setState({loading: true});

        await DB.update(`activities`, new DBParams(this.state.entity.id, {status: constants.ENTITY_STATUS_BLOCKED}))
        await Promise.all(
            (await db.collection('stories')
                .where('activity.id', '==', this.state.entity.id)
                .where('status', '==', constants.ENTITY_STATUS_ACTIVE)
                .get())
                .docs
                .map(async doc => {
                    await doc.ref.set({activity: {status: constants.ENTITY_STATUS_BLOCKED}}, {merge: true})
                    return doc.id;
                })
        );
        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    }

    handleUnblockEntity = async () => {
        this.setState({loading: true});
        await Promise.all(
            (await db.collection('stories')
                .where('activity.id', '==', this.state.entity.id)
                .where('status', '==', constants.ENTITY_STATUS_BLOCKED)
                .get())
                .docs
                .map(async doc => {
                    await doc.ref.set({
                        activity: {status: constants.ENTITY_STATUS_ACTIVE},
                        status: constants.ENTITY_STATUS_ACTIVE,
                    }, {merge: true})
                    return doc.id;
                })
        );
        await DB.update(`activities`, new DBParams(this.state.entity.id, {status: constants.ENTITY_STATUS_ACTIVE}))

        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    };

    handleResolveReport = () => {
        if (this.props.handleResolveReport !== null && this.props.handleResolveReport !== undefined) {
            this.props.handleResolveReport();
        }
    }

    handleCloseBlockDialog = () => {
        this.handleResolveReport();
    }

    render() {

        const {classes, origin} = this.props;
        const {loading, anchorElement, entity, entityStatusChangeInProgress} = this.state;

        return (
            <React.Fragment>
                {loading || !entity ? <Loader/> : (
                    <React.Fragment>
                        <Card className={classes.card}>
                            <CardHeader
                                action={
                                    <Button
                                        aria-owns={entity ? 'simple-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleOpenEntityMenu}
                                    >
                                        <MoreVert/>
                                    </Button>
                                }
                                title={entity.name && entity.name.length > 20 ? `${entity.name.substring(0, 20)}...` : entity.name}
                                subheader={`tag: ${entity.tag}`}
                            />


                            <CardActions>

                                {entity.status === constants.LOCATION_STATUS_BLOCKED &&
                                <Chip
                                    avatar={<Avatar><BlockIcon/></Avatar>}
                                    label="Blocked"
                                    color="secondary"
                                    className={classes.chip}
                                />
                                }
                            </CardActions>
                        </Card>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REGULAR && !entityStatusChangeInProgress && entity && (
                                <MenuItem
                                    onClick={entity.status === constants.LOCATION_STATUS_ACTIVE ? this.handleBlockEntity : this.handleUnblockEntity}
                                >
                                    {entity.status === constants.LOCATION_STATUS_ACTIVE ? 'Block' : 'Unblock'}
                                </MenuItem>
                            )}
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REPORT && !entityStatusChangeInProgress && entity && [
                                (<MenuItem key={'blockKey'} onClick={this.handleBlockEntity}>Block</MenuItem>),
                                (<MenuItem key={'sanctionKey'}
                                           onClick={this.props.handleSanctionEntity}>Sanction</MenuItem>),
                            ]}
                        </Menu>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={this.props.handleCloseEntityDialog}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

OneActivity.propTypes = {
    classes: PropTypes.object.isRequired,
    entityId: PropTypes.string.isRequired,
    handleCloseEntityDialog: PropTypes.func.isRequired,
    handleResolveReport: PropTypes.func,
    handleSanctionEntity: PropTypes.func,
    origin: PropTypes.string.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(OneActivity))