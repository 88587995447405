import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        fab: {
            position: "fixed",
            right: '20px',
            bottom: '20px',
        },
    }
});
