import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Login from "./pages/Login";
import Page404 from "./pages/Page404";
import Admin from "pages/Admin";
import Tos from "./pages/Tos";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import * as routes from './common/routes';
import {combineReducers, createStore} from 'redux';
import personReducer from './store/reducers/person';
import authReducer from './store/reducers/auth';
import routerReducer from './store/reducers/router';
import {Provider} from 'react-redux';
import TermsOfConduct from "./pages/TermsOfConduct";

const rootReducer = combineReducers({
    per: personReducer,
    auth: authReducer,
    router: routerReducer,
});
const store = createStore(rootReducer);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path={routes.ADMIN} component={Admin}/>
                <Route path={routes.LOGIN} component={Login}/>
                <Route path={routes.TOS} component={Tos}/>
                <Route path={routes.PRIVACY} component={PrivacyPolicy}/>
                {process.env.NODE_ENV === 'development' &&
                (<Route path={routes.TERMS_OF_CONDUCT} component={TermsOfConduct}/>)
                }
                <Route path="*" component={Page404}/>
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
