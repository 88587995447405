import React from "react";
import PropTypes from "prop-types";
import {createMuiTheme, MuiThemeProvider, withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import * as constants from 'common/constants';
import styles from "./styles";
import DB from "common/DB";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import * as utils from "common/utils";
import OneActivity from "components/admin/OneActivity";
import StatusChip from "components/StatusChip";
import DBParams from "common/DBParams";
import {db} from "common/firebase";
import myTheme from "common/theme";
import Button from "@material-ui/core/Button";

const collectionName = 'activities';
const searchColumns = [
    'tagNormalized',
];

const filtersColumnsNamesMap = {
    status: {
        index: 3,
    },
};

const filtersColumnsIndexesMap = {
    3: {
        label: 'status',
    },
};

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            anchorElement: null,
            theOneEntity: null,
            entityDialogOpen: false,

            data: [],
            currentPage: 0,
            rowsPerPage: 5,
            lastVisible: [],
            count: 0,
            orderBy: 'tagNormalized',
            orderDirection: 'asc',
            searchText: '',
            serverSideFilterList: [],
            filters: [],
            columnsState: [
                { display: 'excluded'},
                { display: 'excluded'},
                { display: 'true' },
                { display: 'true' },
            ],
            tableSearched: false,
            tableFiltered: false,
            fetchingData: false,
        };
    }

    get isEntityDialogOpen() {
        return utils.notNullOrUndefined(this.state.theOneEntity) && this.state.entityDialogOpen;
    }

    get lastVisible() {
        return this.state.lastVisible[this.state.currentPage - 1];
    }

    componentDidMount() {

        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        if (this.state.fetchingData) {
            console.info('fetching data ALREADY in progress!!! (reloadTable)');
            return;
        }

        await this.setState({
            fetchingData: true,
        });

        if (this.state.tableSearched) {
            this.reloadTableOnSearch();
            return;
        }

        if (this.state.tableFiltered) {
            this.reloadTableOnFilter();
            return;
        }

        try {

            await DB.get('statics', new DBParams('counts'))
                .then(async res => {
                    await this.setState({count: res[collectionName]});
                });

            let query = db.collection(collectionName);

            query = query.orderBy(this.state.orderBy, this.state.orderDirection);

            // pagination
            if (utils.notNullOrUndefined(this.lastVisible)) {
                query = query.startAfter(this.lastVisible);
            }

            await query
                .limit(this.state.rowsPerPage)
                .get()
                .then(async res => {
                    const lastVisible = this.state.lastVisible;
                    lastVisible[this.state.currentPage] = res.size > 0 ? res.docs[res.size - 1] : [];
                    const count = this.state.count;
                    await this.setState({
                        data: res.docs.map(doc => utils.getDataWithIdFromFirebaseDocument(doc)),
                        lastVisible: lastVisible,
                        fetchingData: false,
                        count: count,
                    });
                })
                .catch(e => {
                    console.error('E0024', collectionName, e.toString());
                })
        } catch (e) {
            console.error('E0025', collectionName, e.toString());
        }
    };

    reloadTableOnSearch = async () => {
        const data = [];
        const uniqueIDs = [];

        try {

            await this.setState({fetchingData: true});

            await Promise.all(
                await searchColumns.map(async searchColumn => {

                    await db.collection(collectionName)
                        .orderBy(searchColumn)
                        .startAt(utils.normalizeStringForSearch(this.state.searchText))
                        .endAt(utils.normalizeStringForSearch(this.state.searchText) + "\uf8ff")
                        .get()
                        .then(async res => {
                            //TODO: maybe filter??
                            res.docs.map(doc => {
                                    if (uniqueIDs.indexOf(doc.id) === -1) {
                                        data.push(utils.getDataWithIdFromFirebaseDocument(doc));
                                        uniqueIDs.push(doc.id);
                                    }
                                    return false;
                                }
                            )
                        })
                        .catch(e => console.error('E0027', e.toString()));

                    return false;
                })
            );

            // sort by orderBy prop
            data.sort(utils.sortArrayOfObjectsAlphabeticallyByKey('usernameNormalized'));
            await this.setState({
                data: data.slice(this.state.currentPage * this.state.rowsPerPage, (this.state.currentPage + 1) * this.state.rowsPerPage),
                count: data.length,
                fetchingData: false,
                tableSearched: true,
            })
        } catch (e) {
            console.error('E0026', collectionName, e.toString());
            this.setState({fetchingData: false});
        }
    };

    handleOnTableChange = async (action, tableState) => {
        // console.info('handleTableChange', action, tableState);
        switch (action) {
            case 'changeRowsPerPage':
                tableState.page = 0;
                break;
            case "columnViewChange":
                const columnsState = this.state.columnsState;
                for (let i=0; i< tableState.columns.length; i++) {
                    columnsState[i].display = tableState.columns[i].display;
                }
                await this.setState({columnsState: columnsState});
                break;
            default:
                break;
        }
    };

    reloadTableOnFilter = async () => {

        try {

            await this.setState({fetchingData: true});

            let query = db.collection(collectionName);

            // filters
            Object.entries(this.state.filters)
                .filter(filter => filter[1].length > 0)
                .forEach(filter => {
                    // use only first filter param
                    const paramName = this.getNormalizedColumn(filtersColumnsIndexesMap[filter[0]].label);
                    query = query.where(paramName, '==', filter[1][0]);
                })

            await query.get()
                .then(async res => {
                    const dataWithoutDeleted = res.docs
                        .filter(doc => doc.data().status !== constants.ENTITY_STATUS_DELETED);

                    const data = dataWithoutDeleted.slice(this.state.currentPage * this.state.rowsPerPage, (this.state.currentPage + 1) * this.state.rowsPerPage);
                    await this.setState({
                        data: data.map(datum => utils.getDataWithIdFromFirebaseDocument(datum)),
                        count: dataWithoutDeleted.length,
                        fetchingData: false,
                    })
                })
                .catch(e => console.error('E0029', collectionName, e.toString()));

        } catch (e) {
            console.error('E0028', collectionName, e.toString());
            this.setState({fetchingData: false});
        }
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            theOneEntity: {
                id: rowData[0],
                status: rowData[1],
            }
        });
        this.handleOpenEntityDialog();
    };

    handleOpenEntityDialog = () => {
        this.setState({entityDialogOpen: true});
    };

    handleCloseEntityDialog = async () => {
        this.setState({entityDialogOpen: false});
        await this.reloadTable();
    };

    getMuiTheme = () => createMuiTheme({
        ...myTheme,
        ...{
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        cursor: 'pointer',
                    }
                },
                MUIDataTableFilter: {
                    root: {
                        minWidth: '300px',
                    }
                }
            }
        },
    });

    getNormalizedColumn = (column) => {
        if (['tag'].indexOf(column) !== -1) {
            return `${column}Normalized`;
        } else {
            return column;
        }
    };

    handleChangePage = async (currentPage) => {
        await this.setState({
            currentPage: currentPage,
        });
        await this.reloadTable();
    };

    handleChangeRowsPerPage = async (rowsPerPage) => {
        await this.setState({
            currentPage: 0,
            lastVisible: [],
            rowsPerPage: rowsPerPage,
        });
        await this.reloadTable();
    };

    handleColumnSortChange = async (changedColumn, direction) => {

        const orderDirection = this.state.orderBy === this.getNormalizedColumn(changedColumn) ?
            this.state.orderDirection === 'asc' ? 'desc' : 'asc'
            : 'desc';

        await this.setState({
            currentPage: 0,
            lastVisible: [],
            orderBy: this.getNormalizedColumn(changedColumn),
            orderDirection: orderDirection
        });

        await this.reloadTable();
    };

    handleSearchClose = async () => {
        this.setState({
            searchText: '',
            tableSearched: false,
        });
    };

    handleSearchChange = async (searchText) => {

        // do regular search
        if (searchText === '' || searchText === null) {
            await this.reloadTable();
            return;
        }

        // go back to regular fetch
        if (searchText.length === 2 && this.state.searchText.length === 3) {
            await this.setState({
                searchText: '',
            });
            await this.reloadTable();
            return;
        }

        // do nothing
        if (searchText.length < 3) {
            return;
        }

        // do regular fetch
        await this.setState({
            currentPage: 0,
            lastVisible: [],
            searchText: searchText,
        });

        await this.reloadTableOnSearch();
    };

    handleFilterSubmit = (filterList, type) => async () => {

        switch (type) {
            case 'button':
            case 'chip':
                await this.setState({tableFiltered: false});
                await Promise.all(
                    await filterList.map(async filter => {
                        if (filter.length > 0) {
                            await this.setState({tableFiltered: true});
                        }
                    })
                );
                await this.setState({
                    currentPage: 0,
                    filters: filterList,
                    serverSideFilterList: filterList,
                });

                await this.reloadTable();
                break;
            case 'reset':
                await this.setState({
                    tableFiltered: false,
                    currentPage: 0,
                    filters: [],
                    serverSideFilterList: [],
                });
                await this.reloadTable();
                break;
            default:
                break;
        }
    }

    render() {

        const {classes, fullScreen} = this.props;
        const {loading, theOneEntity, data, currentPage, rowsPerPage, searchText, count, serverSideFilterList, columnsState} = this.state;

        const columns = [
            {
                name: "id",
                options: {
                    filter: false,
                    sort: false,
                    display: columnsState[0].display,
                }
            },
            {
                name: "status",
                options: {
                    filter: false,
                    sort: false,
                    display: columnsState[1].display,
                }
            },
            {
                label: "Tag",
                name: "tag",
                options: {
                    filter: false,
                    sort: true,
                    display: columnsState[2].display,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Status",
                name: "status",
                options: {
                    filter: true,
                    filterList: this.state.filters[3],
                    customFilterListRender: v => `Status: ${v}`,
                    filterOptions: {
                        names: [constants.ENTITY_STATUS_ACTIVE, constants.ENTITY_STATUS_BLOCKED],
                    },
                    sort: false,
                    display: columnsState[3].display,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<StatusChip value={value}/>)
                    }
                },
            },
        ];

        const options = {
            filter: true,
            serverSideFilterList: serverSideFilterList,
            filterType: 'dropdown',
            onFilterChange: (column, filterList, type) => {
                switch (type) {
                    case 'chip':
                        filterList = this.state.filters;
                        filterList[filtersColumnsNamesMap[column].index] = [];
                        this.handleFilterSubmit(filterList, type)();
                        break;
                    case 'dropdown':
                        break;
                    case 'reset':
                        this.handleFilterSubmit([], type)();
                        break;
                    default:
                        console.info('unknown filter action', type);
                        break;

                }
            },
            customFilterDialogFooter: filterList => {
                return (
                    <div style={{marginTop: '40px'}}>
                        <Button variant="outlined"
                                onClick={this.handleFilterSubmit(filterList, 'button')}>
                            Apply Filters
                        </Button>
                    </div>
                );
            },
            responsive: "stacked",
            selectableRows: 'none',
            rowHover: true,
            serverSide: true,
            search: true,
            searchText: searchText,
            page: currentPage,
            count: count,
            download: false,
            print: false,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [5, 10, 50],
            textLabels: {
                body: {
                    noMatch:
                        'Sorry, there is no matching data to display',
                },
            },
            onColumnSortChange: this.handleColumnSortChange,
            onChangeRowsPerPage: this.handleChangeRowsPerPage,
            onChangePage: this.handleChangePage,
            onSearchClose: this.handleSearchClose,
            onSearchChange: this.handleSearchChange,
            onRowClick: this.handleRowClick,
            onTableChange: this.handleOnTableChange,
        };

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                            {this.state.fetchingData && (
                                <React.Fragment>
                                    <div className={classes.tableFetchingDataOverlay}>
                                    </div>
                                    <div className={classes.tableLoader}>
                                        <Loader/>
                                    </div>
                                </React.Fragment>
                            )}
                        </MuiThemeProvider>
                        {theOneEntity && (
                            <Dialog
                                fullScreen={fullScreen}
                                open={this.isEntityDialogOpen}
                                onClose={this.handleCloseEntityDialog}
                                aria-labelledby="responsive-dialog-title"
                                BackdropProps={{className: classes.backdrop}}
                            >
                                <DialogContent
                                    className={classes.theOneEntityDialog}
                                >
                                    <OneActivity
                                        entityId={theOneEntity.id}
                                        handleBlockEntity={this.handleBlockEntity}
                                        handleUnblockEntity={this.handleUnblockEntity}
                                        handleCloseEntityDialog={this.handleCloseEntityDialog}
                                        origin={constants.ONE_ENTITY_COMPONENT_TYPE_REGULAR}
                                    />
                                </DialogContent>
                            </Dialog>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Index)