import axios from 'axios';

const API = process.env.REACT_APP_API_URL;

class Api {

    static get = (query) => {
        return new Promise((resolve, reject) => {
            axios.get(API + query)
                .then(result => {
                    console.log(`API call=> GET:'${API + query}' successful!`);
                    if (process.env.NODE_ENV === 'development') {
                        console.info(result);
                    }
                    resolve(result);
                })
                .catch(error => {
                    console.error(`ERROR-001:: API call=> GET:'${API + query}' unsuccessful!`);
                    if (process.env.NODE_ENV === 'development') {
                        console.info(error);
                    }
                    reject(error);
                });
        }
    )};

    static post = (query, data={}, config={}) => {
        return new Promise((resolve, reject) => {
            axios.post(API + query, data, config)
                .then(result => {
                    console.log(`API call=> POST:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`ERROR-002:: API call=> POST:'${API + query}' with data: '${JSON.stringify(data)}' unsuccessful!`);
                    reject(error);
                });
        });
    };

    static put = (query, data={}, config={}) => {
        return new Promise((resolve, reject) => {
            axios.put(API + query, data, config)
                .then(result => {
                    console.log(`API call=> PUT:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`ERROR-003:: API call=> PUT:'${API + query}' with data: '${JSON.stringify(data)}' unsuccessful!`);
                    reject(error);
                });
        });
    };

    static delete = (query, config={}) => {
        return new Promise((resolve, reject) => {
            axios.delete(API + query, config)
                .then(result => {
                    console.log(`API call=> DELETE:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`ERROR-004:: API call=> DELETE:'${API + query}' unsuccessful!`);
                    reject(error);
                });
        });
    };
}

export default Api;