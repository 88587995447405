export const USER_ACCESS_LEVEL_EMPLOYEE = 'employee';
export const USER_ACCESS_LEVEL_MANAGER = 'manager';
export const USER_ACCESS_LEVEL_DEV = 'dev';
//TODO: remove and use generic aka: one-entity-to-rule-them-all
export const PERSON_STATUS_ACTIVE = 'active';
export const PERSON_STATUS_BLOCKED = 'blocked';
export const STORY_STATUS_ACTIVE = 'active';
export const STORY_STATUS_BLOCKED = 'blocked';
export const STORY_STATUS_DELETED = 'deleted';
export const ACTIVITY_STATUS_ACTIVE = 'active';
export const ACTIVITY_STATUS_BLOCKED = 'blocked';
export const LOCATION_STATUS_ACTIVE = 'active';
export const LOCATION_STATUS_BLOCKED = 'blocked';

export const ACCOUNT_TYPE_FREE = 'free';
export const ACCOUNT_TYPE_PREMIUM = 'premium';

export const ENTITY_STATUS_ACTIVE = 'active';
export const ENTITY_STATUS_BLOCKED = 'blocked';
export const ENTITY_STATUS_DELETED = 'deleted';

export const WORD_STATUS_INACTIVE = 'inactive';
export const WORD_STATUS_ACTIVE = 'active';
export const IMAGE_STATUS_INACTIVE = 'inactive';
export const IMAGE_STATUS_ACTIVE = 'active';
export const REPORT_STATUS_UNRESOLVED = 'unresolved';
export const REPORT_STATUS_RESOLVED = 'resolved';
export const STORY_SCORE_FLAGGED_BREAKPOINT = 100;
export const STORY_VISIBILITY_PUBLIC = 'public';
export const STORY_VISIBILITY_PRIVATE = 'private';
export const STORY_TYPE_REGULAR = 'regular';
export const STORY_TYPE_HELP = 'help'
export const ONE_ENTITY_COMPONENT_TYPE_REGULAR = 'regular';
export const ONE_ENTITY_COMPONENT_TYPE_REPORT = 'report';
export const ONE_ENTITY_COMPONENT_TYPE_FLAGGED = 'flagged';

export const SOCIAL_MEDIA_TYPE_FACEBOOK = "facebook";
export const SOCIAL_MEDIA_TYPE_FACEBOOK_BASE_URL = "https://www.facebook.com/";
export const SOCIAL_MEDIA_TYPE_INSTAGRAM = "instagram";
export const SOCIAL_MEDIA_TYPE_INSTAGRAM_BASE_URL = "https://www.instagram.com/";
export const SOCIAL_MEDIA_TYPE_TWITTER = "twitter";
export const SOCIAL_MEDIA_TYPE_TWITTER_BASE_URL = "https://twitter.com/";
export const SOCIAL_MEDIA_TYPE_WEBSITE = "website";
export const SOCIAL_MEDIA_TYPE_WEBSITE_BASE_URL = "https://";
