import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
// import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import PropTypes from "prop-types"
import {withStyles} from "@material-ui/core"
import styles from "./styles";

function MadeWithLove() {
    return (
        <Typography variant="body2" color="textSecondary">
            {''}
            <Link color="inherit" href="https://material-ui.com/">

            </Link>
            {''}
        </Typography>
    );
}

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errorOccurred: false,
            errorText: '',
        }
    }

    componentDidCatch(error, info) {
        this.setState({
            errorOccurred: true,
            errorText: error,
        })
        // logErrorToMyService(error, info)
        console.info('E0000', error, info);
    }

    render() {

        const {classes} = this.props;
        const {errorOccurred} = this.state;

        return errorOccurred ? (
            <React.Fragment>
                <div className={classes.root}>
                    <CssBaseline/>
                    <div className={classes.main} maxWidth="sm">
                        <Typography variant="h2" component="h1" gutterBottom>
                            Error Page
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom>
                            {'Something went wrong!'}
                        </Typography>
                    </div>
                    <footer className={classes.footer}>
                        <div maxWidth="sm">
                            <MadeWithLove/>
                        </div>
                    </footer>
                </div>
            </React.Fragment>
        ) : (this.props.children)
    }
}

ErrorHandler.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ErrorHandler);
