import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import * as constants from 'common/constants';
import styles from "./styles";
import moment from 'moment';
import DB from "common/DB";
import DBParams, {WhereCondition} from "common/DBParams";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import OnePoi from "components/admin/OnePoi";
import OneStory from "components/admin/OneStory";
import OnePerson from "components/admin/OnePerson";
import OneActivity from "components/admin/OneActivity";
import * as utils from "common/utils";
import * as _ from 'lodash';

const namesMap = {
    pois: {
        collectionReported: 'reportedPois',
        propertyInStory: 'pointOfInterest',
        oneComponent: 'OnePoi',
    },
    activities: {
        collectionReported: 'reportedActivities',
        propertyInStory: 'activity',
        oneComponent: 'OneActivity',
    },
    stories: {
        collectionReported: 'reportedStories',
        propertyInStory: 'invalidAndItIsOk',
        oneComponent: 'OneStory',
    },
    persons: {
        collectionReported: 'reportedPersons',
        propertyInStory: 'author',
        oneComponent: 'OnePerson',
    },
};

class ReportedContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            entitiesData: [],
            actualData: [],
            anchorElement: null,
            theOneEntity: null,
            reportId: null,
            entityDialogOpen: false,
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get entities() {
        return this.state.actualData;
    }

    get isEntityDialogOpen() {
        return utils.notNullOrUndefined(this.state.theOneEntity) && this.state.entityDialogOpen;
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        try {
            await DB.get(namesMap[this.props.collectionName].collectionReported, new DBParams(null, {
                whereConditions: [
                    new WhereCondition('status', '==', constants.REPORT_STATUS_UNRESOLVED),
                ]
            }))
                .then(async results => {

                    const actualData = [];
                    await Promise.all(results.map(async result => {
                        await DB.get(this.props.collectionName, new DBParams(result.reported))
                            .then(res => actualData.push({
                                ...result, // reported entity
                                ...res, // actual entity
                                ...{reportID: result.id},
                            }));
                    }))

                    await this.setState({
                        entitiesData: results,
                        actualData: actualData,
                    });
                });
        } catch (e) {
            console.error('E0008', this.props.collectionName, e);
            await this.setState({
                entitiesData: [],
            });
        }
    };

    handleRowClick = async (rowData, rowMeta) => {
        await DB.get(this.props.collectionName, new DBParams(rowData[1]))
            .then(async res => {
                await this.setState({
                    reportId: rowData[0],
                    theOneEntity: res,
                });
                this.handleOpenEntityDialog();
            })

    };

    handleOpenEntityDialog = () => {
        this.setState({entityDialogOpen: true});
    };

    handleResolveReport = async () => {
        // resolve in reportedWHATEVER collection
        await DB.update(namesMap[this.props.collectionName].collectionReported, new DBParams(this.state.reportId, {status: constants.REPORT_STATUS_RESOLVED}))
        this.handleCloseEntityDialog();
        await this.reloadTable();
    };

    handleCloseEntityDialog = async () => {
        await this.reloadTable();
        this.setState({entityDialogOpen: false});
    };

    handleSanctionEntity = async () => {
        await this.handleResolveReport();
    };

    handleTableChange = (action, tableState) => {
        if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    getColumns = () => {
        let columns = _.union(
            [
                {
                    name: "reportID",
                    options: {
                        filter: false,
                        sort: false,
                        display: 'excluded',
                    }
                },
                {
                    name: "reported",
                    options: {
                        filter: false,
                        sort: false,
                        display: 'excluded',
                    }
                },
                {
                    label: "Reported By Person",
                    name: "reportedBy",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                    },
                },
                {
                    label: "Reported Entity",
                    name: "reported",
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                    },
                },
                {
                    label: "Reason",
                    name: "reason",
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                    },
                },
            ],
            this.props.additionalColumns,
            [
                {
                    label: "Date",
                    name: "date",
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return value ? (
                                moment(parseInt(value.timestamp)).format("LLL")
                            ) : '-'
                        }
                    },
                },
            ]
        );

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if(this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    render() {

        const {classes, fullScreen} = this.props;
        const {loading, theOneEntity, reportId} = this.state;

        const components = {
            OnePoi: OnePoi,
            OneStory: OneStory,
            OnePerson: OnePerson,
            OneActivity: OneActivity,
        };

        const SingleViewComponent = components[namesMap[this.props.collectionName].oneComponent];

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.entities}
                            columns={this.getColumns()}
                            options={{
                                filterType: 'checkbox',
                                responsive: "stacked",
                                selectableRows: 'none',
                                onRowClick: this.handleRowClick,
                                filter: false,
                                download: false,
                                print: false,
                                onTableChange: this.handleTableChange,
                                searchText: this.getSearchText(),
                            }}
                        />
                        {theOneEntity && (
                            <Dialog
                                fullScreen={fullScreen}
                                open={this.isEntityDialogOpen}
                                onClose={this.handleCloseEntityDialog}
                                aria-labelledby="responsive-dialog-title"
                                BackdropProps={{className: classes.backdrop}}
                            >
                                <DialogContent
                                    className={classes.theOneEntityDialog}
                                >
                                    <SingleViewComponent
                                        entityId={theOneEntity.id}
                                        reportId={reportId}
                                        // handleBlockEntity={this.handleBlockEntity}
                                        handleCloseEntityDialog={this.handleCloseEntityDialog}
                                        handleResolveReport={this.handleResolveReport}
                                        handleSanctionEntity={this.handleSanctionEntity}
                                        origin={constants.ONE_ENTITY_COMPONENT_TYPE_REPORT}
                                    />
                                </DialogContent>
                            </Dialog>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

ReportedContent.propTypes = {
    classes: PropTypes.object.isRequired,
    collectionName: PropTypes.string.isRequired,
    additionalColumns: PropTypes.array,
};

ReportedContent.defaultProps = {
    collectionName: 'unknown',
    additionalColumns: [],
};

export default withStyles(styles, {withTheme: true})(ReportedContent)