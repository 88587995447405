import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DrawerMenu from "components/admin/DrawerMenu";
import {auth} from "common/firebase";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {connect} from "react-redux";
import * as actionTypes from "store/actions";
import Loader from "components/Loader";
import * as routes from 'common/routes';
import styles from "./styles";
import myTheme from 'common/theme';
import axios from "axios";
import ErrorHandler from "components/ErrorHandler";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import clsx from 'clsx';
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from "@material-ui/core/Divider";
import {MuiThemeProvider} from "@material-ui/core";

const AdminContext = React.createContext();

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initializing: true,
            open: props.width !== 'xs',
            // page: menus[0].entries[process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PAGE_SELECTED : 0],
            user: null,
            anchorEl: null,
            changePasswordDialogOpen: false,
            passwordChangeInProgress: false,
            oldPasswordCheckResultErrorMessage: '',

            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',

            showOldPassword: false,
            showNewPassword: false,
            showConfirmationPassword: false,
        };

        auth.onAuthStateChanged((user) => {

            this.setState({user: user});

            //TODO: check for listeners
            if (user) {
                user.getIdToken(true)
                    .then(idToken => {
                        // User is signed in.
                        if (process.env.NODE_ENV !== 'production') {
                            console.info(`token: Bearer ${idToken}`);
                        }
                        axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
                        this.props.onAddUser(user);
                        this.setState({initializing: false});
                    })
                    .catch(error => {
                        console.error('E0020: ', error);
                        this.props.onRemoveUser();
                        this.setState({initializing: false});
                        axios.defaults.headers.common['Authorization'] = null;
                    });

            } else {
                console.error('E0021: ', 'no user!');
                this.props.onRemoveUser();
                this.setState({initializing: false});
                axios.defaults.headers.common['Authorization'] = null;
            }
        });
    }

    get arePasswordsValid() {
        // return true;
        let ok = true;
        [
            'oldPassword',
            'newPassword',
            'confirmationPassword',
        ].forEach(pass => {
            if (this.state[pass].length < 8) {
                ok = false;
            }
            if (!this.isPasswordValid(pass)) {
                ok = false;
            }
        })
        return ok;
    }

    componentDidMount() {

    }

    handleOpenChangePasswordDialog = () => {
        this.setState({changePasswordDialogOpen: true});
        this.handleClose(); // dropdown menu
    }

    handleCloseChangePasswordDialog = () => {
        this.setState({
            changePasswordDialogOpen: false,
            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',
            showOldPassword: false,
            showNewPassword: false,
            showConfirmationPassword: false,
        });
    }

    isPasswordValid = (type) => {
        return this.getErrorText(type) === ' ';
    }

    handleChangePassword = () => {
        this.setState({passwordChangeInProgress: true});

        // check old pass
        auth.signInWithEmailAndPassword(this.props.user.email, this.state.oldPassword)
            .then(async res => {
                // old password valid, try to change pass
                await this.state.user.updatePassword(this.state.newPassword)
                    .then(() => {
                        // successfully changed pass
                        this.setState({
                            passwordChangeInProgress: false,
                        });
                        this.handleCloseChangePasswordDialog();
                        //TODO: force logout ??
                        // firebase.auth().signOut().then(function() {
                        //     // Sign-out successful.
                        // }).catch(function(error) {
                        //     // An error happened.
                        // });
                    })
                    .catch(e => {
                        // something went terribly wrong with firebase!!
                        console.error('E0022', 'error password update', e);
                        this.setState({
                            oldPasswordCheckResultErrorMessage: e.message,
                            passwordChangeInProgress: false,
                        });
                    })
            })
            .catch(e => {
                // old password is not valid
                console.error('E0023', e.toString());
                this.setState({
                    oldPasswordCheckResultErrorMessage: e.message,
                    passwordChangeInProgress: false,
                });
            });
    }

    getErrorText = type => {
        let msg = ' ';
        const passInQuestion = this.state[type];

        // if (passInQuestion.length === 0) {
        //     return msg;
        // }

        // eslint-disable-next-line no-unused-vars
        const {oldPassword, newPassword, confirmationPassword, oldPasswordCheckResultErrorMessage} = this.state;

        if (passInQuestion.length < 8) {
            msg = 'Password must be at least 8 chars long'
        }

        switch (type) {

            case 'oldPassword':
                if (oldPasswordCheckResultErrorMessage.length > 0) {
                    msg = oldPasswordCheckResultErrorMessage;
                } else {
                    msg = passInQuestion.length === 0 ? ' ' : msg;
                }
                break;
            case 'newPassword':
                msg = passInQuestion.length === 0 ? ' ' : msg;
                break;
            case 'confirmationPassword':
                if (newPassword !== confirmationPassword) {
                    msg = 'Passwords mismatch!';
                }
                msg = passInQuestion.length === 0 ? ' ' : msg;
                break;
            default:
                break;
        }
        return msg;
    }

    handlePasswordInputFieldChange = type => (event, props) => {
        this.setState({
            [type]: event.target.value,
            oldPasswordCheckResultErrorMessage: '',
        });
    }

    handleClickShowPassword = type => () => {
        const showPassword = this.state[type];
        this.setState({[type]: !showPassword})
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleMenuChange = (page) => {
        // this.setState({page: page})
        this.props.onChangePage(page);
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogout = async () => {
        await auth.signOut();
        this.handleClose();
    };

    render() {
        const {classes, user, width, page} = this.props;
        const {
            initializing,
            open,
            anchorEl,
            changePasswordDialogOpen,
            passwordChangeInProgress,

            confirmationPassword,
            newPassword,
            oldPassword,

            showOldPassword,
            showNewPassword,
            showConfirmationPassword
        } = this.state;


        if (initializing) {
            return <Loader/>
        }

        if (user === null || user === undefined) {
            window.location = routes.LOGIN;
            return null;
        }

        // user.getIdTokenResult()
        //     .then((idTokenResult) => {
        //         // Confirm the user is an Admin.
        //
        //         console.info('claims', idTokenResult.claims.accessLevel);
        //
        //         if (!!idTokenResult.claims.admin) {
        //             // Show admin UI.
        //             console.info('showAdminUI');
        //             // showAdminUI();
        //         } else {
        //             // Show regular user UI.
        //             console.info('showRegularUI');
        //             // showRegularUI();
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        return (
            <MuiThemeProvider theme={myTheme}>
                <div className={classes.root}>
                    <ErrorHandler>
                        <AdminContext.Provider
                            value={{
                                drawerOpen: open,
                                onMenuChange: this.handleMenuChange
                            }}
                        >
                            <CssBaseline/>
                            <AppBar
                                position="absolute"
                                className={classNames(classes.appBar, open && classes.appBarShift)}
                            >
                                <Toolbar disableGutters={!open} className={classes.toolbar}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="Open drawer"
                                        onClick={this.handleDrawerOpen}
                                        className={classNames(
                                            classes.menuButton,
                                            open && classes.menuButtonHidden,
                                        )}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        noWrap
                                        className={classes.title}
                                    >
                                        {page.primary}
                                    </Typography>
                                    <Button color="inherit"
                                            onClick={this.handleMenu}
                                    >
                                        {isWidthUp('md', width) &&
                                        <Typography
                                            className={classes.accountEmail}
                                        >{user.email}</Typography>
                                        }
                                        <AccountCircle className={classes.rightIcon}/>
                                    </Button>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.handleOpenChangePasswordDialog}>Change
                                            Password</MenuItem>
                                        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                variant="permanent"
                                classes={{
                                    paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                                }}
                                open={this.state.open}
                            >
                                <div className={classes.toolbarIcon}>
                                    <IconButton onClick={this.handleDrawerClose}>
                                        <ChevronLeftIcon/>
                                    </IconButton>
                                </div>
                                <List><DrawerMenu/></List>
                            </Drawer>

                            <main className={classes.content}>
                                <div className={classes.appBarSpacer}/>
                                {page.component}
                            </main>
                        </AdminContext.Provider>
                    </ErrorHandler>
                    <Dialog
                        open={changePasswordDialogOpen}
                        onClose={this.handleCloseNewEmployeeDialog}
                        aria-labelledby="responsive-dialog-title"
                    >
                        {passwordChangeInProgress ? (
                            <div className={classes.changePasswordLoaderContainer}>
                                <Loader/>
                            </div>
                        ) : (
                            <React.Fragment>
                                <DialogTitle id="responsive-dialog-title">{"Change Password"}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        className={clsx(classes.margin, classes.textField)}
                                        variant="outlined"
                                        fullWidth={true}
                                        error={!this.isPasswordValid('oldPassword')}
                                        helperText={this.getErrorText('oldPassword')}
                                        type={showOldPassword ? 'text' : 'password'}
                                        label="Old Password"
                                        value={oldPassword}
                                        onChange={this.handlePasswordInputFieldChange('oldPassword')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword('showOldPassword')}
                                                    >
                                                        {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Divider
                                        light={true}
                                    />
                                    <TextField
                                        className={clsx(classes.margin, classes.textField)}
                                        variant="outlined"
                                        fullWidth={true}
                                        error={!this.isPasswordValid('newPassword')}
                                        helperText={this.getErrorText('newPassword')}
                                        type={showNewPassword ? 'text' : 'password'}
                                        label="New Password"
                                        value={newPassword}
                                        onChange={this.handlePasswordInputFieldChange('newPassword')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword('showNewPassword')}
                                                    >
                                                        {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Divider
                                        light={true}
                                    />
                                    <TextField
                                        className={clsx(classes.margin, classes.textField)}
                                        variant="outlined"
                                        fullWidth={true}
                                        error={!this.isPasswordValid('confirmationPassword')}
                                        helperText={this.getErrorText('confirmationPassword')}
                                        type={showConfirmationPassword ? 'text' : 'password'}
                                        label="Confirm New Password"
                                        value={confirmationPassword}
                                        onChange={this.handlePasswordInputFieldChange('confirmationPassword')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword('showConfirmationPassword')}
                                                    >
                                                        {showConfirmationPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseChangePasswordDialog} color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.handleChangePassword}
                                        disabled={!this.arePasswordsValid}
                                        color="primary"
                                        autoFocus
                                    >
                                        Change
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        )}
                    </Dialog>
                </div>
            </MuiThemeProvider>
        );
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        page: state.router.page,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddUser: (user) => dispatch({type: actionTypes.ADD_USER, user: user}),
        onRemoveUser: () => dispatch({type: actionTypes.REMOVE_USER}),
        onChangePage: (page) => dispatch({type: actionTypes.CHANGE_PAGE, page: page}),
    }
};

export const AdminContextConsumer = AdminContext.Consumer;

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles, {withTheme: true})(Index)));
