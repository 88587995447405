import React, {Component} from 'react';

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            content: 'to be implemented',
        };
    }

    render() {

        const {content} = this.state;

        return (
            <div>{content.text}</div>
        )
    }
}

export default PrivacyPolicy