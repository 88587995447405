import {db} from './firebase';
import DBParams from "./DBParams";
import * as utils from "./utils";

class DB {
    /**
     *
     * @param URL string
     * @param params DBParams
     * @param raw boolean
     * @return {Promise<object>}
     */
    static get = (URL, params = new DBParams(), raw = false) => {

        // throw new Error(`invalid DB params: ${params}`);

        // console.info('not happening tabun!!');
        if (!(params instanceof DBParams)) {
            throw new Error(`invalid DB params: ${params}`);
        }

        return new Promise((resolve, reject) => {

            try {

                if (params.doc === null) {
                    // multiple documents
                    let query = db.collection(URL);

                    // apply where conditions, if any
                    params.data.whereConditions.forEach(whereCondition => {
                        // console.info('wc', whereCondition);
                        query = query.where(whereCondition.property, whereCondition.comparator, whereCondition.value);
                    })

                    // apply ordering if sent
                    if (utils.notNullOrUndefined(params.data.orderBy)) {
                        query = query.orderBy(
                            params.data.orderBy,
                            utils.notNullOrUndefined(params.data.orderDirection) ? params.data.orderDirection : 'asc',
                        );
                    }

                    query
                        .get()
                        .then(result => {
                            if (process.env.NODE_ENV === 'development') {
                                console.info('firebase SKD', 'GET', URL, result);
                            }
                            resolve(raw === true ? result : result.docs.map(doc => utils.getDataWithIdFromFirebaseDocument(doc)));
                        })
                        .catch(error => {
                            console.error('E0001', URL, error);
                            reject(error);
                        })
                } else {
                    // one document
                    db.collection(URL).doc(params.doc).get()
                        .then(result => {
                            if (process.env.NODE_ENV === 'development') {
                                console.info('firebase SKD', 'GET', URL, params.doc, result);
                            }
                            resolve(raw === true ? result : utils.getDataWithIdFromFirebaseDocument(result));
                        })
                        .catch(error => {
                            console.error('E0003', URL, params.doc, error);
                            reject(error);
                        })
                }
            } catch (e) {
                console.error('E0004', URL, params.doc, e);
                reject(e);
            }
        });
    };

    /**
     *
     * @param URL string
     * @param params DBParams
     * @return {Promise<object>}
     */
    static add = (URL, params = new DBParams()) => {
        if (!(params instanceof DBParams)) {
            throw new Error(`invalid DB params: ${params}`);
        }

        return new Promise((resolve, reject) => {

            try {

                db.collection(URL)
                    .add(params.data)
                    .then(result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.info('firebase SKD', 'ADD', URL, result);
                        }
                        resolve(true);
                    })
                    .catch(error => {
                        console.error('E0006', error);
                        reject(error);
                    })
            } catch (e) {
                console.error('E0007', e);
                reject(e);
            }
        });
    };

    /**
     *
     * @param URL string
     * @param params DBParams
     * @return {Promise<object>}
     */
    static update = (URL, params = new DBParams()) => {
        if (!(params instanceof DBParams)) {
            throw new Error(`invalid DB params: ${params}`);
        }

        return new Promise((resolve, reject) => {

            try {

                db.collection(URL).doc(params.doc)
                    .set(params.data, {merge: true})
                    .then(result => {
                        if (process.env.NODE_ENV === 'development') {
                            console.info('firebase SKD', 'UPDATE', URL, result);
                        }
                        resolve(true);
                    })
                    .catch(error => {
                        console.error('E0002', error);
                        reject(error);
                    })
            } catch (e) {
                console.error('E0005', e);
                reject(e);
            }
        });
    };

    static delete = (URL, params = new DBParams()) => {
        return new Promise((resolve, reject) => {

            if (!(params instanceof DBParams)) {
                throw new Error(`invalid DB params: ${params}`);
            }

            try {

                db.collection(URL).doc(params.doc).delete()
                    .then(res => {
                        console.info('del result!', res);
                        resolve(true);
                    })
                    .catch(e => {
                        console.error('E0018', e.toString());
                        reject(e);
                    })
            } catch (e) {
                console.error('E0017', e);
                reject(e);
            }
        });
    };
}

export default DB;