import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        tableLoader: {
            position: 'absolute',
            left: '50%',
            top: '50%',
        },
        tableFetchingDataOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            background: `rgba(0, 180, 219, 0.05)`,
            zIndex: 1000,
            display: 'block',
        },
    }
});
