import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
        versionLabel: {
            textAlign: 'right',
        },
        button: {
            marginRight: theme.spacing.unit,
        },
        expansionPanel: {
            marginBottom: theme.spacing.unit,
            marginTop: theme.spacing.unit,
        },
    }
});
