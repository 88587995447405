import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        fab: {
            position: "fixed",
            right: '20px',
            bottom: '20px',
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
        },
    }
});
