import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {auth} from 'common/firebase';
import * as routes from "../common/routes";

class Login extends React.Component {

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            'password',
        ],
        signInSuccessUrl: routes.ADMIN,
        callbacks: {
            signInSuccessWithAuthResult: () => true,
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            isSignedIn: false,
        }
    }

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        this.unregisterAuthObserver = auth.onAuthStateChanged(
            (user) => this.setState({isSignedIn: !!user})
        );
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        return (
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={auth}/>
        );
    }
}

export default Login;