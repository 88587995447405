export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    footer: {
        padding: theme.spacing.unit,
        marginTop: 'auto',
        backgroundColor: 'white',
    },
});
