import React, {Component} from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import {withStyles} from '@material-ui/core';

// Material components
import {Typography} from '@material-ui/core';

// Material icons
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    LoyaltyOutlined as LoyaltyIcon
} from '@material-ui/icons';

// Shared components
import {Paper} from 'components';

// Component styles
import styles from './styles';

class Stories extends Component {
    render() {
        const {classes, className, data, ...rest} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Paper
                {...rest}
                className={rootClassName}
            >
                <div className={classes.content}>
                    <div className={classes.details}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                        >
                            TOTAL STORIES
                        </Typography>
                        <Typography
                            className={classes.value}
                            variant="h3"
                        >
                            {data.total}
                        </Typography>
                    </div>
                    <div className={classes.iconWrapper}>
                        <LoyaltyIcon className={classes.icon}/>
                    </div>
                </div>
                <div className={classes.footer}>
                    <Typography
                        className={classes.difference}
                        variant="body2"
                    >
                        {data.percentage >= 0 ? (
                            <ArrowUpwardIcon/>
                        ) : (
                            <ArrowDownwardIcon/>
                        )}
                        {data.percentage}%
                    </Typography>
                    <Typography
                        className={classes.caption}
                        variant="caption"
                    >
                        Since last month
                    </Typography>
                </div>
            </Paper>
        );
    }
}

Stories.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Stories);
