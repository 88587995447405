import React, {Component} from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Bar} from 'react-chartjs-2';
import * as utils from 'common/utils';

// Material helpers
import {withStyles} from '@material-ui/core';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletToolbar,
    PortletContent,
    PortletFooter
} from 'components';

// Chart configuration
import {options} from './chart';

// Component styles
import styles from './styles';
import palette from "../../theme/palette";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const CHART_RANGE_LAST_7_DAYS = 'last7days';
const CHART_RANGE_LAST_30_DAYS = 'last30days';
const CHART_RANGE_LAST_365_DAYS = 'last365days';

class SalesChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chartRange: CHART_RANGE_LAST_7_DAYS,
            last365: Object.entries(this.props.paymentStats.statsPurchases.last365days),
        }
    }

    componentDidMount() {
    }

    every_nth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);

    data = () => {

        let dataRaw = [];
        let data = [];

        // format fetched data based on chart range
        switch (this.state.chartRange) {
            case CHART_RANGE_LAST_7_DAYS:
                data = this.state.last365.slice(this.state.last365.length - 7, this.state.last365.length)
                    .map(datum => {
                        return {
                            label: datum[1].label.substring(5),
                            prices: datum[1].prices,
                        }
                    });
                break;
            case CHART_RANGE_LAST_30_DAYS:
                dataRaw = this.state.last365.slice(this.state.last365.length - 30, this.state.last365.length);
                data = [];
                for (let i = 0; i < dataRaw.length; i += 3) {
                    const label = `${dataRaw[i][1].label.substring(5)} - ${dataRaw[i + 2][1].label.substring(5)}`;
                    const onemonthPricesSum = dataRaw[i][1].prices.onemonth + dataRaw[i + 1][1].prices.onemonth + dataRaw[i + 2][1].prices.onemonth;
                    const threemonthsPricesSum = dataRaw[i][1].prices.threemonths + dataRaw[i + 1][1].prices.threemonths + dataRaw[i + 2][1].prices.threemonths;
                    const yearPricesSum = dataRaw[i][1].prices.year + dataRaw[i + 1][1].prices.year + dataRaw[i + 2][1].prices.year;

                    data.push({
                        label: label,
                        prices: {
                            onemonth: onemonthPricesSum,
                            threemonths: threemonthsPricesSum,
                            year: yearPricesSum,
                        }
                    })
                }
                break;
            case CHART_RANGE_LAST_365_DAYS:
                dataRaw = this.state.last365;
                data = [];
                for (let i = 0; i < dataRaw.length; i++) {

                    const aData = dataRaw[i][1];
                    const dData = data[dataRaw[i][0].substring(0, 7)];

                    if (utils.isNullOrUndefined(dData)) {
                        data[dataRaw[i][0].substring(0, 7)] = {
                            label: aData.label.substring(5, 8),
                            prices: {
                                onemonth: 0,
                                threemonths: 0,
                                year: 0,
                            }
                        };
                    }

                    data[dataRaw[i][0].substring(0, 7)].prices.onemonth += aData.prices.onemonth;
                    data[dataRaw[i][0].substring(0, 7)].prices.threemonths += aData.prices.threemonths;
                    data[dataRaw[i][0].substring(0, 7)].prices.year += aData.prices.year;
                }

                data = Object.entries(data).map(datum => datum[1]);
                break;
            default:

                break;
        }

        // final formatting that is the same for all three chart types
        const labels = data.map(obj => {
            return obj.label;
        });
        const dataOneMonthSubscription = data.map(obj => {
            return obj.prices['onemonth'];
        })
        const dataThreeMonthsSubscription = data.map(obj => {
            return obj.prices['threemonths'];
        })
        const dataYearSubscription = data.map(obj => {
            return obj.prices['year'];
        })

        return {
            labels: labels,
            datasets: [
                {
                    label: 'One Month Subscription',
                    backgroundColor: palette.primary.main,
                    data: dataOneMonthSubscription,
                },
                {
                    label: 'Three Months Subscription',
                    backgroundColor: palette.secondary.main,
                    data: dataThreeMonthsSubscription,
                },
                {
                    label: 'One Year Subscription',
                    backgroundColor: palette.primary.dark,
                    data: dataYearSubscription,
                },
            ]
        };
    }

    handleChangeChartRange = event => {
        this.setState({chartRange: event.target.value});
    }

    render() {

        const {chartRange} = this.state;
        const {classes, className, paymentStats, ...rest} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <Portlet
                {...rest}
                className={rootClassName}
            >
                <PortletHeader noDivider>
                    <PortletLabel title="IN-APP PURCHASES"/>
                    <PortletToolbar>

                        <Select
                            value={chartRange}
                            onChange={this.handleChangeChartRange}
                        >
                            <MenuItem value={CHART_RANGE_LAST_7_DAYS}>Last 7 Days</MenuItem>
                            <MenuItem value={CHART_RANGE_LAST_30_DAYS}>Last 30 days</MenuItem>
                            <MenuItem value={CHART_RANGE_LAST_365_DAYS}>Last 365 days</MenuItem>
                        </Select>
                    </PortletToolbar>
                </PortletHeader>
                <PortletContent>
                    <div className={classes.chartWrapper}>
                        <Bar
                            data={this.data}
                            options={options}
                        />
                    </div>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                </PortletFooter>
            </Portlet>
        );
    }
}

SalesChart.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    paymentStats: PropTypes.object.isRequired,
};

export default withStyles(styles)(SalesChart);
