import React from "react";
import ReportedContent from 'components/admin/ReportedContent';

class ReportedActivities extends React.Component {

    render() {
        return <ReportedContent
            collectionName={'activities'}
            additionalColumns={[
                {
                    label: "Tag",
                    name: "tag",
                    options: {
                        filter: false,
                        sort: false,
                    },
                },
            ]}
        />
    }
}

export default ReportedActivities