import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import {storage} from 'common/firebase';
import TextField from "@material-ui/core/TextField";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {db} from "common/firebase";
import * as utils from "common/utils";

const imprints = {
    emergency: {
        en_GB: `imprints/emergency.en.md`,
        de_DE: `imprints/emergency.de.md`,
    },
    guidelines: {
        en_GB: `imprints/guidelines.en.md`,
        de_DE: `imprints/guidelines.de.md`,
    },
    imprint: {
        en_GB: `imprints/imprint.en.md`,
        de_DE: `imprints/imprint.de.md`,
    },
    privacy: {
        en_GB: `imprints/privacy'.en.md`,
        de_DE: `imprints/privacy.de.md`,
    },
    terms: {
        en_GB: `imprints/terms.en.md`,
        de_DE: `imprints/terms.de.md`,
    },
};

class Imprints extends React.Component {

    localizationService;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            version: null,
            imprint: null,
            imprintText: '',
            editDialogOpen: false,
        };
    }

    componentDidMount() {

        this.fetchData()
            .then(async () => {
                await this.setState({
                    loading: false,
                })
            })
    }

    async fetchData() {

        await db.collection('statics').doc('imprints').get()
            .then(async res => {
                await this.setState({
                    version: res.data()._version,
                })
            });
    }

    setExpanded(panel) {
        this.setState({expanded: panel});
    }

    handleGroupClick = (panel, newExpanded) => {
        this.setExpanded(newExpanded ? panel : '');
    };

    handlePanelChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    getText = async (url) => {
        return new Promise(((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.send(null);
            request.onreadystatechange = function () {
                if (request.readyState === 4 && request.status === 200) {
                    const type = request.getResponseHeader('Content-Type');
                    if (type.indexOf("text") !== 1) {
                        resolve(request.responseText);
                    }
                }
            }
        }))
    };

    handleOpenEditDialog = (imprint, language) => async () => {

        const name = imprint[0];
        const storageRef = storage.ref('imprints');
        const imprintRef = storageRef.child(`${name}.${language}.md`);
        imprintRef.getDownloadURL()
            .then(async URL => {
                const text = await this.getText(URL);
                await this.setState({
                    imprintText: text,
                    imprint: {
                        name: name,
                        language: language,
                        ref: imprintRef,
                    },
                    editDialogOpen: true,
                })
            })
            .catch(e => {
                console.error('E0031', e);
            });
    };

    handleCloseEditDialog = () => {
        this.setState({
            imprint: null,
            imprintText: null,
            editDialogOpen: false,
        })
    };

    handleSaveImprintData = async () => {
        await this.setState({working: true});

        this.state.imprint.ref.putString(this.state.imprintText).then(async snapshot => {

            await db.collection('statics').doc('imprints').set({
                _version: utils.incrementVersion(this.state.version),
            }, {merge: true})
                .then(async () => {
                    await this.setState({working: false});
                    await this.fetchData();
                    this.handleCloseEditDialog();
                })
        });
    };

    handleTextFieldChange = async event => {
        await this.setState({imprintText: event.target.value})
    };

    render() {

        const {classes} = this.props;
        const {loading, expanded, version, editDialogOpen, imprintText, imprint, working} = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        {version && (
                            <Typography className={classes.versionLabel}>version {version}</Typography>
                        )}
                        {Object.entries(imprints).map(imprint => (

                           <div key={imprint[0]}>
                               <ExpansionPanel
                                   expanded={expanded === imprint[0]}
                                   onChange={this.handlePanelChange(imprint[0])}
                                   className={classes.expansionPanel}
                               >
                                   <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                       <Typography className={classes.heading}>{imprint[0]}</Typography>
                                   </ExpansionPanelSummary>
                                   <ExpansionPanelDetails>
                                       <Button
                                           className={classes.button}
                                           variant={"contained"}
                                           onClick={this.handleOpenEditDialog(imprint, 'en')}
                                       >
                                           English
                                       </Button>
                                       <Button
                                           className={classes.button}
                                           variant={"contained"}
                                           onClick={this.handleOpenEditDialog(imprint, 'de')}
                                       >
                                           German
                                       </Button>
                                   </ExpansionPanelDetails>
                               </ExpansionPanel>
                           </div>
                        ))}

                        {imprint && (
                            <Dialog
                                open={editDialogOpen}
                                onClose={this.handleCloseEditDialog}
                                aria-labelledby="form-dialog-title"
                                fullWidth={true}
                            >
                                {working ? (
                                    <Loader/>
                                ) : (
                                    <React.Fragment>
                                        <DialogTitle id="form-dialog-title">Imprint: <strong>{imprint.name}</strong>, Language: <strong>{imprint.language}</strong></DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="imprintTextField"
                                                // label="Imprint Text"
                                                variant={"outlined"}
                                                multiline={true}
                                                fullWidth
                                                value={imprintText}
                                                onChange={this.handleTextFieldChange}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={this.handleCloseEditDialog}
                                                // color="secondary"
                                                variant={'contained'}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={this.handleSaveImprintData}
                                                // color="primary"
                                                variant={'contained'}
                                            >
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                )}
                            </Dialog>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

Imprints.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Imprints)