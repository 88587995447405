import React from "react";
import PropTypes from "prop-types";
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import myTheme from 'common/theme';
import * as utils from "common/utils";
import * as constants from "common/constants";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
    hamburgerButton: {
        color: '#ffffff',
    }
});

class StatusChip extends React.Component {

    render() {

        const {classes, value} = this.props;

        return (
            <MuiThemeProvider theme={myTheme}>
                <Chip
                    // avatar={<Avatar><BlockIcon/></Avatar>}
                    label={utils.jsUcfirst(value)}
                    color={value === constants.PERSON_STATUS_ACTIVE ? "primary" : "secondary"}
                    className={classes.hamburgerButton}
                    clickable={true}
                />
            </MuiThemeProvider>
        )
    }
}

StatusChip.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(StatusChip)