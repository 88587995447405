import * as actionTypes from '../actions';

const initialState = {
    persons: [],
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.ADD_PERSON:
            return {
                ...state,
                persons: state.persons.concat({...action.payload, id: new Date()}),
            };
        case actionTypes.DELETE_PERSON:
            return {
                ...state,
                persons: state.persons.filter(result => result.id !== action.personId)
            };
        default:
            return state;
    }
};

export default reducer;