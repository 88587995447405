import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import * as constants from 'common/constants';
import styles from "./styles";
import moment from 'moment';
import DB from "common/DB";
import DBParams, {WhereCondition} from "common/DBParams";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import OneStory from "components/admin/OneStory";
import * as utils from "common/utils";

class FlaggedStories extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            entitiesData: [],
            anchorElement: null,
            entityDialogOpen: false,
            theOneEntity: null,
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get isEntityDialogOpen() {
        return utils.notNullOrUndefined(this.state.theOneEntity) && this.state.entityDialogOpen;
    }

    get entities() {
        return this.state.entitiesData;
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        try {
            await DB.get('stories', new DBParams(null, {
                whereConditions: [
                    new WhereCondition('score', '>=', constants.STORY_SCORE_FLAGGED_BREAKPOINT)
                ],
                orderBy: 'score',
                orderDirection: 'desc',
            }))
                .then(async storiesData => {
                    await this.setState({
                        entitiesData: storiesData.filter(storyData => storyData.status === constants.STORY_STATUS_ACTIVE),
                    });
                });
        } catch (e) {
            console.error('E0010', e);
            await this.setState({
                entitiesData: [],
            });
        }
    };

    handleRowClick = async (rowData, rowMeta) => {

        this.handleOpenEntityDialog();

        await this.setState({
            theOneEntity: {
                id: rowData[0],
                score: rowData[1],
            }
        });
    };

    handleOpenEntityDialog = () => {
        this.setState({entityDialogOpen: true});
    };

    handleCloseEntityDialog = async () => {
        this.setState({entityDialogOpen: false});
        await this.reloadTable();
    };

    handleUnflagEntity = async () => {
        await DB.update('stories', new DBParams(this.state.theOneEntity.id, {score: -1}));
        await this.reloadTable();
        await this.handleCloseEntityDialog();
    };

    handleTableChange = (action, tableState) => {
        if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    getColumns = () => {
        let columns = [
            {
                label: "ID",
                name: "id",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "score",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                label: "Author's UID",
                name: "author",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value.id : '-',
                },
            },
            {
                label: "Score",
                name: "score",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                }
            },
            {
                label: "Message",
                name: "message",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            value !== null && value.length > 20 ? `${value.substring(0, 20)}...` : (value ? value : '-')
                        )
                    }
                }
            },
            {
                label: "Range",
                name: "range",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? `${value}m` : '-',
                }
            },
            {
                label: "Visibility",
                name: "visibility",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                }
            },
            {
                label: "Date Created",
                name: "date",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value.timestamp ? moment(parseInt(value.timestamp)).format("LLL") : '-',
                },
            },
            {
                label: "Expiration Date",
                name: "expirationDate",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value.timestamp ? moment(parseInt(value.timestamp)).format("LLL") : '-',
                },
            },
            {
                label: "Type",
                name: "type",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Issue",
                name: "issue",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
        ];

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if(this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    render() {

        const {fullScreen, classes} = this.props;
        const {loading, theOneEntity} = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.entities}
                            columns={this.getColumns()}
                            options={{
                                filterType: 'checkbox',
                                responsive: "stacked",
                                download: false,
                                print: false,
                                rowHover: false,
                                selectableRows: 'none',
                                onRowClick: this.handleRowClick,
                                onTableChange: this.handleTableChange,
                                searchText: this.getSearchText(),
                            }}
                        />
                        {theOneEntity && (
                            <Dialog
                                fullScreen={fullScreen}
                                open={this.isEntityDialogOpen}
                                onClose={this.handleCloseEntityDialog}
                                aria-labelledby="responsive-dialog-title"
                                BackdropProps={{className: classes.backdrop}}
                            >
                                <DialogContent>
                                    <OneStory
                                        entityId={theOneEntity.id}
                                        // handleBlockEntity={this.handleBlockEntity}
                                        // handleUnblockEntity={this.handleUnblockEntity}
                                        handleUnflagEntity={this.handleUnflagEntity}
                                        handleCloseEntityDialog={this.handleCloseEntityDialog}
                                        origin={constants.ONE_ENTITY_COMPONENT_TYPE_FLAGGED}
                                    />
                                </DialogContent>
                            </Dialog>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

FlaggedStories.propTypes = {
    classes: PropTypes.object.isRequired,
    // collectionName: PropTypes.string.isRequired,
};

FlaggedStories.defaultProps = {};

export default withStyles(styles, {withTheme: true})(FlaggedStories)