import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import * as constants from 'common/constants';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import styles from './styles';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import ReactTimeout from "react-timeout";
import DB from "common/DB";
import DBParams from "common/DBParams";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import * as utils from "common/utils";
import CloseIcon from "@material-ui/icons/Close";


class OneStory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            cardExpanded: false,
            anchorElement: null,
            entity: null,
            entityStatusChangeInProgress: false,
            blockDialogOpen: false,
            blockReason: '',
        };
    }

    get isBlockDialogOpen() {
        return utils.notNullOrUndefined(this.state.entity) && this.state.blockDialogOpen;
    }

    componentDidMount() {
        this.fetchEntity()
            .then(async () => await this.setState({loading: false}));
    }

    fetchEntity = async () => {
        await DB.get('stories', new DBParams(this.props.entityId))
            .then(async result => {
                await DB.get('persons', new DBParams(result.author.id))
                    .then(async res => {
                        result.author = res;
                        await this.setState({entity: result});
                    })
            })
    };

    handleExpandClick = () => {
        this.setState({cardExpanded: !this.state.cardExpanded});
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    // handleBlockEntity = async () => {
    //     this.setState({loading: true});
    //
    //     await DB.update(`stories`, new DBParams(this.props.entityId, {
    //         status: constants.ENTITY_STATUS_BLOCKED,
    //     }))
    //     this.handleCloseBlockDialog();
    //     this.props.handleCloseEntityDialog();
    // }

    handleUnblockEntity = async () => {
        this.setState({loading: true});
        await DB.update(`stories`, new DBParams(this.props.entityId, {
            status: constants.ENTITY_STATUS_ACTIVE,
            blockReason: null,
        }))
        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    };

    handleResolveReport = () => {
        if (this.props.handleResolveReport !== null && this.props.handleResolveReport !== undefined) {
            this.props.handleResolveReport();
        }
    }

    handleUnflagEntity = async () => {
        await this.setState({loading: true});
        await this.props.handleUnflagEntity();
        await this.setState({loading: false});
    }

    // handleCloseBlockDialog = () => {
    //     this.handleResolveReport();
    // }

    handleSanctionEntity = async () => {
        await this.setState({loading: true});
        console.info('OneStory -> handleSanctionEntity');
        await this.props.handleSanctionEntity();
        await this.setState({
            blockDialogOpen: false,
            anchorElement: false,
        });
        await this.setState({loading: false});
    }

    handleBlockEntity = () => {
        this.handleOpenBlockDialog();
    }

    handleCloseBlockDialog = () => {
        this.handleResolveReport();
        this.setState({
            blockDialogOpen: false,
            anchorElement: false,
        });
    }

    handleOpenBlockDialog = () => {
        this.setState({blockDialogOpen: true});
    }

    handleConfirmBlock = async () => {
        this.setState({loading: true});
        await DB.update(`stories`, new DBParams(this.props.entityId, {
            status: constants.ENTITY_STATUS_BLOCKED,
            blockReason: this.state.blockReason,
        }))
        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    }

    handleChange = event => {
        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    render() {

        const {classes, origin} = this.props;
        const {loading, cardExpanded, anchorElement, entity, entityStatusChangeInProgress, blockReason} = this.state;

        return (
            <React.Fragment>
                {loading || !entity ? <Loader/> : (
                    <React.Fragment>
                        <Card className={classes.card}>
                            <CardHeader
                                action={
                                    <Button
                                        aria-owns={entity ? 'simple-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleOpenEntityMenu}
                                    >
                                        <MoreVert/>
                                    </Button>
                                }
                                title={entity.message && entity.message.length > 20 ? `${entity.message.substring(0, 20)}...` : entity.message}
                                subheader={`by: ${entity.author.username}`}
                            />
                            <CardMedia
                                className={clsx(classes.media, classes.avatarImage)}
                                image={entity.image && entity.image.url}
                                title="Story Photo"
                            />
                            {entity.message && (
                                <CardContent>
                                    <Typography variant={'h6'}>Message</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {entity.message}
                                    </Typography>
                                </CardContent>
                            )}

                            <CardActions>

                                {entity.status === constants.STORY_STATUS_BLOCKED &&
                                <Chip
                                    avatar={<Avatar><BlockIcon/></Avatar>}
                                    label="Blocked"
                                    color="secondary"
                                    className={classes.chip}
                                />
                                }
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: cardExpanded,
                                    })}
                                    onClick={this.handleExpandClick}
                                    aria-expanded={cardExpanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </CardActions>
                            <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {entity.range && <Typography paragraph>
                                        Story Range: <span className={classes.propertyValue}>{entity.range}</span>
                                    </Typography>}
                                    {entity.geoLocation && <Typography paragraph>
                                        Location Hash: <span
                                        className={classes.propertyValue}>{entity.geoLocation.hash}</span>
                                    </Typography>}
                                    {entity.date && <Typography paragraph>
                                        Date Created: <span
                                        className={classes.propertyValue}>{moment(parseInt(entity.date.timestamp)).format("LLL")}</span>
                                    </Typography>}
                                    {entity.expirationDate && <Typography paragraph>
                                        Expiration Date: <span
                                        className={classes.propertyValue}>{moment(parseInt(entity.expirationDate.timestamp)).format("LLL")}</span>
                                    </Typography>}
                                </CardContent>
                            </Collapse>
                        </Card>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REGULAR && !entityStatusChangeInProgress && entity && (
                                <MenuItem
                                    onClick={entity.status === constants.LOCATION_STATUS_ACTIVE ? this.handleOpenBlockDialog : this.handleUnblockEntity}
                                >
                                    {entity.status === constants.LOCATION_STATUS_ACTIVE ? 'Block' : 'Unblock'}
                                </MenuItem>
                            )}
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REPORT && !entityStatusChangeInProgress && entity && [
                                (<MenuItem key={'blockKey'} onClick={this.handleOpenBlockDialog}>Block</MenuItem>),
                                (<MenuItem key={'sanctionKey'}
                                           onClick={this.handleSanctionEntity}>Sanction</MenuItem>),
                            ]}
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_FLAGGED && !entityStatusChangeInProgress && entity && [
                                (<MenuItem key={'blockKey'} onClick={this.handleOpenBlockDialog}>Block</MenuItem>),
                                (<MenuItem key={'unflagKey'}
                                           onClick={this.handleUnflagEntity}>Unflag</MenuItem>),
                            ]}
                        </Menu>
                        <Dialog
                            open={this.isBlockDialogOpen}
                            onClose={this.handleCloseBlockDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Do you want to block this story?
                                </DialogContentText>
                                <TextField
                                    value={blockReason}
                                    margin="dense"
                                    id="blockReason"
                                    name="blockReason"
                                    label="Block Reason"
                                    rowsMax={5}
                                    rows={5}
                                    multiline={true}
                                    type="text"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={entityStatusChangeInProgress}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseBlockDialog} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={this.handleConfirmBlock} color="primary" autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={this.props.handleCloseEntityDialog}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

OneStory.propTypes = {
    classes: PropTypes.object.isRequired,
    entityId: PropTypes.string.isRequired,
    handleCloseEntityDialog: PropTypes.func.isRequired,
    handleResolveReport: PropTypes.func,
    handleSanctionEntity: PropTypes.func,
    handleUnflagEntity: PropTypes.func,
    origin: PropTypes.string.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(OneStory))