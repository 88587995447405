import React, {Component} from 'react';
import {auth, authObject} from "common/firebase";
import * as utils from "common/utils";

const qs = require('query-string');

class TermsOfConduct extends Component {

    componentDidMount() {
        const {__mobile, __code, __withBearer} = qs.parse(this.props.location.search);

        // Turn off phone auth app verification.

        if (utils.notNullOrUndefined(__mobile) && utils.notNullOrUndefined(__code)) {

            auth.settings.appVerificationDisabledForTesting = true;
            const appVerifier = new authObject.RecaptchaVerifier('recaptcha-container');
            auth.signInWithPhoneNumber(`+${__mobile}`, appVerifier)
                .then(function (confirmationResult) {
                    // confirmationResult can resolve with the whitelisted testVerificationCode above.
                    return confirmationResult.confirm(__code)
                        .then(async res => {
                            const t = await res.user.getIdToken();
                            const tt = __withBearer ? `Bearer ${t}` : t;
                            console.info('tt:', tt);
                            await navigator.clipboard.writeText(tt);
                        })
                })
                .catch(function (error) {
                    // Error; SMS not sent
                    console.error('E0009', error.toString());
                });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="recaptcha-container">

                </div>
                not f0und
            </React.Fragment>
        )
    }
}

export default TermsOfConduct

