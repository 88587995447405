import React from "react";
import ReportedContent from 'components/admin/ReportedContent';

class ReportedStories extends React.Component {

    render() {
        return <ReportedContent
            collectionName={'stories'}
        />
    }
}

export default ReportedStories