export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line eqeqeq,no-mixed-operators
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 *
 * @param str string
 * @return string
 */
export function jsUcfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * check and format phone number...
 * @param phoneNumber string
 * @param countryCode string
 * @return string
 */
export function ironPhoneNumber(phoneNumber, countryCode = '') {
    let ironedWhatever = normalizeStringForSearch(phoneNumber);
    if (countryCode && ironedWhatever.charAt(0) === '0') {
        ironedWhatever = /* + */ countryCode + ironedWhatever.substring(1, ironedWhatever.length);
    }
    return ironedWhatever;
}

/**
 *
 * @param string string
 * @return string
 */
export function normalizeStringForSearch(string) {
    let normalizedString;
    normalizedString = string.replace(/\s/g, '');
    normalizedString = normalizedString.toLowerCase();
    return normalizedString;
}

export function isUserAuthenticated(user) {
    return user !== null;
}

export function getDataWithIdFromFirebaseDocument(doc) {
    if (!doc.exists) {
        throw new Error(`Document with ID: '${doc.id}' does not exists!`);
    }
    return {
        ...doc.data(),
        ...{id: doc.id},
    };
}

/**
 *
 * @param dataString string
 * @return string
 */
// @ts-ignore
export function decodeBase64Image(dataString) {

    try {
        // eslint-disable-next-line no-useless-escape
        const matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

        if (matches.length !== 3) {
            return new Error('Invalid input string');
        }

        return {
            type: matches[1],
            data: new Buffer(matches[2], 'base64'),
            extension: matches[1].split('/')[1],
        };
    } catch (e) {
        console.info('E0011: ', e.errorInfo);
        return null;
    }
}

/**
 *
 * @param object object
 * @return object
 */
export function detailedLog(object) {
    const util = require('util');
    return util.inspect(object, {showHidden: true, depth: null});
}

/**
 *
 * @param max number
 * @return number
 */
export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

/**
 *
 * @param timestamp string
 * @return string
 */
export function timestampToHumanDateTime(timestamp) {
    try {
        const moment = require('moment');
        return moment(parseInt((timestamp))).locale('rs').format(moment.HTML5_FMT.DATETIME_LOCAL);
    } catch (e) {
        console.error('E0016', e);
        return 'invalid!'
    }
}

/**
 *
 * @param object Object
 * @return Object
 */
export function cleanObjectOfUndefineds(object) {
    Object.keys(object).forEach(key => object[key] === undefined && delete object[key]);
    return object;
}

export function swapUndefinedAndNullWithReplacement(object, replacement) {

}

/**
 *
 * @param date
 * @param timestampFormat
 * @return {*}
 */
export function getStartAndEndOfMonth(date, timestampFormat = true) {
    const moment = require("moment");
    const dateFrom = moment([date.year(), date.month(), 1]);
    const dateTo = moment([date.year(), 0, 31]).add(date.month(), 'months');

    return timestampFormat ?
        {dateFrom: dateFrom.format('x'), dateTo: dateTo.format('x')} :
        {dateFrom: dateFrom.format('LLL'), dateTo: dateTo.format('LLL')}
}

export function notNullOrUndefined(value) {
    return value !== null && value !== undefined;
}

export function isNullOrUndefined(value) {
    return !notNullOrUndefined(value);
}

/**
 *
 * @param length
 * @return string
 */
export function randomString(length = 10) {
    return (Math.random().toString(36).substring(2, length / 2 + 3) + Math.random().toString(36).substring(2, length / 2 + 3)).substring(0, length);
}

export function firstValidFromArray(array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i] !== null && array[i] !== undefined) {
            return array[i];
        }
    }
}

export function generatePreview(htmlString, replacementData) {
    let replacedString = htmlString;
    if (replacementData) {
        Object.keys(replacementData).forEach((key) => {
            replacedString = replacedString.split(`{${key}}`).join(replacementData[key]);
        })
    }
    return replacedString;
}

export function timestampToHumanFriendlyObject(timestamp) {

    const moment = require("moment");

    return {
        timestamp: timestamp.toString(),
        parsed: moment(timestamp).format('LLL'),
    };
}

export function sortObjectKeysAlphabetically(obj) {
    const sortObject = require('sort-object-keys');
    return sortObject(obj);
}

export function sortCustomObjectKeysAlphabetically(obj, customSorFunc) {
    const sortObject = require('sort-object-keys');
    return sortObject(obj, customSorFunc);
}

export const sortArrayOfObjectsAlphabeticallyByKey = (key) => (a, b) => {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }
    return 0;
}

export function pressKey(code) {
    const keyboardEvent = document.createEvent("KeyboardEvent");
    const initMethod = typeof keyboardEvent.initKeyboardEvent !== 'undefined' ? "initKeyboardEvent" : "initKeyEvent";

    keyboardEvent[initMethod](
        "keydown", // event type: keydown, keyup, keypress
        true,      // bubbles
        true,      // cancelable
        window,    // view: should be window
        false,     // ctrlKey
        false,     // altKey
        false,     // shiftKey
        false,     // metaKey
        code,        // keyCode: unsigned long - the virtual key code, else 0
        0          // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
    );
    document.dispatchEvent(keyboardEvent);
}

export function incrementVersion(version, type = 'patch') {
    const semver = require('semver');
    return semver.inc(version, type);
}