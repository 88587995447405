import {db} from 'common/firebase';
import * as utils from 'common/utils';

const _ = require('lodash');
const semver = require('semver');

class StaticsService {
    documentName;
    collectionName;
    _changes = {};
    _backupData = {};

    constructor() {
        this._db = db;
    }

    _db;

    get db() {
        return this._db;
    }

    _data = {};

    get data() {
        if (utils.isNullOrUndefined(this.doc)) {
            console.error('E0012', `${this.collectionName}/${this.documentName} data not fetched!`);
            throw new Error(`${this.collectionName}/${this.documentName} data not fetched!`);
        } else {
            return this._data;
            // return _.omit(this._data, ['_version']);
        }
    }

    _doc;

    get doc() {
        return this._doc;
    }

    set doc(value) {
        this._doc = value;
    }

    get version() {
        return this.getFinalData()['_version'];
    }

    set version(value) {
        this.data['_version'] = value;
    }

    setProp(name, value) {
        this._changes[name] = value;
        return this;
    }

    removeProp(name) {
        this._changes = _.omit(this._changes, [name]);
        this._data = _.omit(this.data, [name]);
        return this;
    }

    clearOnePropChanges(name) {
        this._changes = _.omit(this._changes, [name]);
        try {
            // @ts-ignore
            this._data[name] = this.doc.data()[name];
            return this;
        } catch (e) {
            console.error('E0013', e.toString());
            throw new Error(e.toString());
        }
    }

    clearAllPropChanges() {
        this._changes = {};
        // @ts-ignore
        this._data = this.doc.data();
        return this;
    }

    getAllProps() {
        return this.getFinalData();
    }

    getProp(name) {
        if (this.getFinalData().hasOwnProperty(name)) {
            return this.getFinalData()[name];
        } else {
            return null;
        }
    }

    incrementVersion(type = 'patch') {
        this.version = semver.inc(this.version, type);
        return this;
    }

    /**
     * return 0 if v1 == v2, or 1 if v1 is greater, or -1 if v2 is greater.
     * @param v1
     * @param v2
     */
    compareVersions(v1, v2) {
        return semver.compare(v1, v2);
    };

    getFinalData(withChanges = true) {
        return withChanges ? {
            // ...{_version: this.version},
            ...this.data,
            ...this._changes,
        } : this._backupData;
    }

    toJSON() {
        return {
            version: this.version,
            [this.documentName]: _.omit(utils.sortObjectKeysAlphabetically(this.getFinalData()), ['_version']),
        };
    }

    async fetchData() {
        this.doc = await this.db.collection(this.collectionName).doc(this.documentName).get();
        if (utils.isNullOrUndefined(this.doc.data())) {
            console.error('E0014', `failed to fetch data!`);
            throw new Error(`failed to fetch data!!`);
        } else {
            // @ts-ignore
            this._data = this.doc.data();
            // @ts-ignore
            this._version = this.doc.data()._version;
            this._backupData = this._data;
        }
        //TODO: is it good idea to return it "this early" ?
        return this;
    }

    async saveData() {

        // if no changes do not save!!
        if (_.keys(this._changes).length === 0) {
            console.info('no changes');
            return this;
        }

        //TODO: if data is identical, do not save!!

        // increment or set to 1.0.0 ONLY IF version is not set manually!
        if (utils.isNullOrUndefined(this._changes['_version'])) {
            this.version = utils.isNullOrUndefined(this._backupData['_version']) ? '1.0.0' : semver.inc(this.version, 'patch');
        }

        // totally replace old document... this is so that values can be removed
        await this.db.collection(this.collectionName).doc(this.documentName)
            .set(this.getFinalData())
            .then(async res => {
                this.clearAllPropChanges();
                await this.fetchData();
            })
            .catch(e => {
                console.error('E0015', `failed to save collection: (${this.documentName}), doc: (${this.documentName}) data!`, e.toString());
                throw new Error(`failed to save collection: (${this.documentName}), doc: (${this.documentName}) data!`);
            });
        //TODO: is it good idea to return it "this early" ?
        return this;
    }
}

export default StaticsService;