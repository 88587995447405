import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from 'common/constants';
import styles from "./styles";
import DB from "common/DB";
import DBParams from "common/DBParams";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import StatusChip from "components/StatusChip";

class Words extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            entitiesData: [],
            anchorElement: null,
            theOneEntity: null,
            addDialogOpen: false,
            working: false,
            score: 0,
            wordText: '',
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get entities() {
        return this.state.entitiesData;
    }

    get isValidScore() {
        return !isNaN(this.state.score) && parseFloat(this.state.score) >= 0;
    }

    get isValidWordText() {
        return this.state.wordText.trim().length > 2;
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        await DB.get('wordsBlacklist')
            .then(async result => {
                await this.setState({
                    entitiesData: result,
                });
            });
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            theOneEntity: {
                id: rowData[0],
                status: rowData[1],
            }
        });
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    handleBlockEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.update(`wordsBlacklist`, new DBParams(this.state.theOneEntity.id, {status: constants.WORD_STATUS_INACTIVE}))
        await this.reloadTable();
    };

    handleUnblockEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.update(`wordsBlacklist`, new DBParams(this.state.theOneEntity.id, {status: constants.WORD_STATUS_ACTIVE}))
        await this.reloadTable();
    };

    handleDeleteEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.delete(`wordsBlacklist`, new DBParams(this.state.theOneEntity.id));
        await this.reloadTable();
    }

    handleOpenAddDialog = () => {
        this.setState({addDialogOpen: true});
    }

    handleCloseAddDialog = () => {
        this.setState({
            addDialogOpen: false
        });
        this.resetStateForNew();
    }

    resetStateForNew = () => {
        this.setState({
            score: 0,
            working: false,
            wordText: '',
        });
    }

    handleCancelNew = () => {
        this.handleCloseAddDialog();
    }

    handleSaveNew = async () => {
        this.setState({
            working: true,
        })

        await DB.update('wordsBlacklist', new DBParams(this.state.wordText, {
                wordText: this.state.wordText,
                score: parseInt(this.state.score),
                status: constants.WORD_STATUS_ACTIVE,
                group: 'uncategorised',
            },
        ))
            .then(async () => {
                await this.reloadTable();
                this.handleCloseAddDialog();
            })
    };

    handleTableChange = (action, tableState) => {
        if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    handleChange = event => {
        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    getColumns = () => {
        let columns = [
            {
                name: "id",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "status",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                label: "Value",
                name: "id",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Score",
                name: "score",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Group",
                name: "group",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Status",
                name: "status",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<StatusChip value={value}/>)
                    }
                },
            },
            {
                label: "Actions",
                name: 'id',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                                aria-owns={this.state.anchorElement ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleOpenEntityMenu}
                            >
                                <MoreVert/>
                            </Button>
                        )
                    }
                },
            },
        ];

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if(this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    render() {

        const {classes, fullScreen} = this.props;
        const {loading, anchorElement, theOneEntity, addDialogOpen, working, score, wordText} = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.entities}
                            columns={this.getColumns()}
                            options={{
                                filterType: 'checkbox',
                                responsive: "stacked",
                                selectableRows: 'none',
                                onRowClick: this.handleRowClick,
                                download: false,
                                print: false,
                                onTableChange: this.handleTableChange,
                                searchText: this.getSearchText(),
                            }}
                        />
                        <Fab className={classes.fab} color={"secondary"} onClick={this.handleOpenAddDialog}>
                            <AddIcon/>
                        </Fab>
                        <Dialog
                            fullScreen={fullScreen}
                            open={addDialogOpen}
                            onClose={this.handleCloseAddDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle
                                id="responsive-dialog-title">{"Add a new Word to Unwanted Words"}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    value={wordText}
                                    margin="dense"
                                    id="wordText"
                                    name="wordText"
                                    label="Word text"
                                    error={!this.isValidWordText}
                                    helperText={!this.isValidWordText ? "Word must have at least 3 letters!" : ""}
                                    type="text"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={working}
                                />

                                <TextField
                                    value={score}
                                    margin="dense"
                                    id="score"
                                    name="score"
                                    label="Score"
                                    error={!this.isValidScore}
                                    helperText={!this.isValidScore ? "Valid score is >= 0 " : ""}
                                    type="number"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={working}
                                />
                            </DialogContent>
                            {working ? (<Loader/>) : (
                                <DialogActions>
                                    <Button onClick={this.handleCancelNew} color="primary" disabled={working}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.handleSaveNew}
                                        color="primary"
                                        autoFocus
                                        disabled={working || !this.isValidWordText || !this.isValidScore}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            )}
                        </Dialog>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {theOneEntity && (
                                <MenuItem onClick={this.handleDeleteEntity}>Delete</MenuItem>
                            )}
                            {theOneEntity && theOneEntity.status === constants.WORD_STATUS_ACTIVE && (
                                <MenuItem onClick={this.handleBlockEntity}>Deactivate</MenuItem>
                            )}
                            {theOneEntity && theOneEntity.status === constants.WORD_STATUS_INACTIVE && (
                                <MenuItem onClick={this.handleUnblockEntity}>Activate</MenuItem>
                            )}
                        </Menu>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

Words.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Words)