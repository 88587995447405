import * as actionTypes from '../actions';
import {menus} from "components/admin/Menus";

const initialState = {
    page: 
        menus[process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PAGE_SELECTED_LVL1 : 0]
        .entries[process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PAGE_SELECTED_LVL2 : 0],
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.CHANGE_PAGE:
            return {
                ...state,
                page: action.page,
            };
        default:
            return state;
    }
};

export default reducer;