import React from "react";
import PropTypes from "prop-types";
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';
import myTheme from 'common/theme';

const styles = theme => ({
    loader: {
        margin: theme.spacing.unit * 2,
    },
});

class Loader extends React.Component {

    render() {

        const {classes} = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.grid}
            >
                <MuiThemeProvider theme={myTheme}>
                    <CircularProgress
                        className={clsx(classes.progress, classes.loader)}
                        color={"primary"}
                    />
                </MuiThemeProvider>
            </Grid>
        )
    }
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Loader)