import * as actionTypes from '../actions';

const initialState = {
    user: null,
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.ADD_USER:
            return {
                ...state,
                user: action.user,
            };
        case actionTypes.REMOVE_USER:
            return {
                ...state,
                user: null
            };
        default:
            return state;
    }
};

export default reducer;