import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import Api from "common/Api";
import Fab from "@material-ui/core/Fab";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from 'common/constants';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import {connect} from "react-redux";
import styles from './styles';
import * as actionTypes from "store/actions";
import {menus} from "components/admin/Menus";
import moment from "moment";
import StatusChip from "components/StatusChip";
import InfoDialog from "components/InfoDialog";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Employees extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            usersData: [],
            anchorElement: null,
            theOneUser: null,
            newEmployeeDialogOpen: false,
            newEmployeeEmail: '',
            newEmployeePassword: '',
            deleteUserDialogOpen: false,
            errorMessage: '',
            infoDialogOpen: false,
            errorTextPassword: '',
            errorTextEmail: '',
            dialogLoading: false,
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get isNewEmployeeEmailValid() {
        return this.state.newEmployeeEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            && this.state.errorTextEmail.length === 0;
    }

    get isNewEmployeePasswordValid() {
        return this.state.newEmployeePassword.length >= 8
            && this.state.errorTextPassword.length === 0;
    }

    get isNewEmployeeCreateButtonDisabled() {
        if (!this.isNewEmployeePasswordValid) {
            return true;
        } else if (!this.isNewEmployeeEmailValid) {
            return true;
        } else {
            return false;
        }
    }

    get users() {
        return this.state.usersData.map(userData => {
            return userData;
        });
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        await Api.get('users')
            .then(async result => {
                await this.setState({
                    usersData: result.data.users,
                    loading: false,
                });
            })
            .catch(e => {
                console.error(e.toString());
                this.props.onChangePage(menus[0].entries[0]);
            })
    };

    showInfo = (message) => {
        this.setState({
            errorMessage: message,
            infoDialogOpen: true,
        })
    };

    handleCloseInfoDialog = () => {
        this.setState({
            errorMessage: '',
            infoDialogOpen: false,
        })
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            theOneUser: {
                uid: rowData[0],
                disabled: rowData[1],
                accessLevel: rowData[2],
            }
        });
    };

    handleOpenUserMenu = async event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseUserMenu = () => {
        this.setState({anchorElement: null});
    };

    handlePromoteUser = async () => {
        this.handleCloseUserMenu();
        this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}/claims`, {
            accessLevel: constants.USER_ACCESS_LEVEL_MANAGER
        });
        await this.reloadTable();
    };

    handleDemoteUser = async () => {
        this.handleCloseUserMenu();
        this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}/claims`, {
            accessLevel: constants.USER_ACCESS_LEVEL_EMPLOYEE,
        });
        await this.reloadTable();
    };

    handleDisableUser = async () => {
        this.handleCloseUserMenu();
        this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}`, {
            disabled: true,
        });
        await this.reloadTable();
    };

    handleEnableUser = async () => {
        this.handleCloseUserMenu();
        this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}`, {
            disabled: false,
        });
        await this.reloadTable();
    };

    handleOpenDeleteUserDialog = () => {
        this.handleCloseUserMenu();
        this.setState({deleteUserDialogOpen: true});
    };

    handleCloseDeleteUserDialog = () => {
        this.setState({deleteUserDialogOpen: false});
    };

    handleDeleteUser = async () => {
        this.handleCloseDeleteUserDialog();
        await this.setState({loading: true});
        await Api.delete(`users/${this.state.theOneUser.uid}`);
        await this.reloadTable();
    };

    handleOpenNewUserDialog = () => {
        this.setState({newEmployeeDialogOpen: true});
    };

    handleCloseNewEmployeeDialog = () => {
        this.setState({newEmployeeDialogOpen: false});
    };

    handleCreateNewEmployee = async () => {
        this.setState({dialogLoading: true});
        await Api.post('users', {
            email: this.state.newEmployeeEmail,
            password: this.state.newEmployeePassword,
        })
            .then(async res => {
                this.handleCloseNewEmployeeDialog();
                this.setState({loading: false});
                await this.reloadTable();
            })
            .catch(async e => {
                console.error('E0030', e.response.data.error);
                await this.setState({
                    errorTextEmail: e.response.data.error,
                    dialogLoading: false,
                });
                // this.showInfo(e.response.data.error);
            });
    };

    handleNewEmployeeEmailChange = (event, prop) => {
        this.setState({
            errorTextEmail: '',
            newEmployeeEmail: event.target.value,
        });
    };

    handleNewEmployeePasswordChange = (event, prop) => {
        this.setState({
            errorTextPassword: '',
            newEmployeePassword: event.target.value,
        });
    };

    handleTableChange = (action, tableState) => {
        if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    getColumns = () => {
        let columns = [
            {
                name: "uid",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "disabled",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "customClaims",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                label: "Email",
                name: "email",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                }
            },
            {
                label: "Mobile",
                name: "phoneNumber",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                }
            },
            {
                label: "Type",
                name: "customClaims",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => value ? value.accessLevel : '-',
                },
            },
            {
                label: "Status",
                name: "disabled",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<StatusChip
                            value={value ? constants.ENTITY_STATUS_BLOCKED : constants.ENTITY_STATUS_ACTIVE}/>)
                    }
                },
            },
            {
                label: "Last Login",
                name: "metadata",
                options: {
                    filter: false,
                    display: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => value.lastSignInTime ? moment(value.lastSignInTime).format("LLL") : '-',
                },
            },
            {
                label: "Date Created",
                name: "metadata",
                options: {
                    filter: false,
                    display: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => value.creationTime ? moment(value.creationTime).format("LLL") : '-',
                },
            },
            {
                label: "Actions",
                name: 'uid',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                                aria-owns={this.state.anchorElement ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleOpenUserMenu}
                            >
                                <MoreVert/>
                            </Button>
                        )
                    }
                },
            },
        ];

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if(this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    render() {

        const {classes, user} = this.props;

        const {
            loading, dialogLoading, anchorElement, theOneUser, deleteUserDialogOpen, newEmployeePassword,
            newEmployeeDialogOpen, newEmployeeEmail, errorTextPassword, errorTextEmail,
        } = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.users}
                            columns={this.getColumns()}
                            options={{
                                filterType: 'checkbox',
                                responsive: "stacked",
                                selectableRows: 'none',
                                onRowClick: this.handleRowClick,
                                print: false,
                                download: false,
                                onTableChange: this.handleTableChange,
                                searchText: this.getSearchText(),
                            }}
                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseUserMenu}
                        >
                            {theOneUser && theOneUser.uid !== user.uid && theOneUser.accessLevel.accessLevel === constants.USER_ACCESS_LEVEL_EMPLOYEE && (
                                <MenuItem onClick={this.handlePromoteUser}>Promote</MenuItem>
                            )}
                            {theOneUser && theOneUser.uid !== user.uid && theOneUser.accessLevel.accessLevel === constants.USER_ACCESS_LEVEL_MANAGER && (
                                <MenuItem onClick={this.handleDemoteUser}>Demote</MenuItem>
                            )}
                            {theOneUser && theOneUser.uid !== user.uid && theOneUser.disabled === true && (
                                <MenuItem onClick={this.handleEnableUser}>Unblock</MenuItem>
                            )}
                            {theOneUser && theOneUser.uid !== user.uid && theOneUser.disabled === false && (
                                <MenuItem onClick={this.handleDisableUser}>Block</MenuItem>
                            )}
                            {theOneUser && theOneUser.uid !== user.uid && (
                                <MenuItem
                                    onClick={this.handleOpenDeleteUserDialog}>Delete</MenuItem>
                            )}
                        </Menu>
                        <Fab className={classes.fab} color={"secondary"} onClick={this.handleOpenNewUserDialog}>
                            <AddIcon/>
                        </Fab>
                        <Dialog
                            open={newEmployeeDialogOpen}
                            onClose={this.handleCloseNewEmployeeDialog}
                            aria-labelledby="responsive-dialog-title"
                            BackdropProps={{className: classes.backdrop}}
                        >
                            {!dialogLoading &&
                            <DialogTitle id="responsive-dialog-title">{"Create new employee"}</DialogTitle>}
                            {dialogLoading ? <Loader/> : (
                                <DialogContent>
                                    <TextField
                                        error={!this.isNewEmployeeEmailValid}
                                        helperText={errorTextEmail}
                                        id="outlined-email"
                                        label="Email"
                                        className={classes.textField}
                                        value={newEmployeeEmail}
                                        onChange={this.handleNewEmployeeEmailChange}
                                        margin="normal"
                                        variant="outlined"
                                        type="email"
                                    />
                                    <TextField
                                        error={!this.isNewEmployeePasswordValid}
                                        helperText={errorTextPassword}
                                        id="outlined-password"
                                        label="Password"
                                        className={classes.textField}
                                        value={newEmployeePassword}
                                        onChange={this.handleNewEmployeePasswordChange}
                                        margin="normal"
                                        variant="outlined"
                                        type="password"
                                    />
                                </DialogContent>
                            )}
                            {!dialogLoading &&
                            <DialogActions>
                                <Button onClick={this.handleCloseNewEmployeeDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.handleCreateNewEmployee}
                                    disabled={this.isNewEmployeeCreateButtonDisabled}
                                    color="primary"
                                    autoFocus
                                >
                                    Create
                                </Button>
                            </DialogActions>
                            }
                        </Dialog>
                        <Dialog
                            open={deleteUserDialogOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleCloseDeleteUserDialog}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            BackdropProps={{className: classes.backdrop}}
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {"Are you sure you want to delete this user?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDeleteUserDialog} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={this.handleDeleteUser} color="primary">
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <InfoDialog
                            message={this.state.errorMessage}
                            open={this.state.infoDialogOpen}
                            handleClose={this.handleCloseInfoDialog}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

Employees.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePage: (page) => dispatch({type: actionTypes.CHANGE_PAGE, page: page}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Employees));