import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import * as constants from 'common/constants';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import styles from './styles';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import ReactTimeout from "react-timeout";
import DB from "common/DB";
import DBParams from "common/DBParams";
import logo_facebook from 'images/logo_facebook.png';
import logo_instagram from 'images/logo_instagram.png';
import logo_twitter from 'images/logo_twitter.png';
import logo_website from 'images/logo_website.png';
import {db} from "common/firebase";
import CloseIcon from "@material-ui/icons/Close";

const logos = {
    facebook: logo_facebook,
    instagram: logo_instagram,
    twitter: logo_twitter,
    website: logo_website,
};

class OnePoi extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            cardExpanded: false,
            anchorElement: null,
            entity: null,
            entityStatusChangeInProgress: false,
        };
    }

    componentDidMount() {
        this.fetchEntity()
            .then(async () => await this.setState({loading: false}));
    }

    fetchEntity = async () => {
        await DB.get('pois', new DBParams(this.props.entityId))
            .then(async result => {
                await this.setState({entity: result});
            })
    };

    handleExpandClick = () => {
        this.setState({cardExpanded: !this.state.cardExpanded});
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    handleOpenSocialMediaLink = (URL, type) => () => {
        let dURL = URL;
        switch (type) {
            case constants.SOCIAL_MEDIA_TYPE_FACEBOOK :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_FACEBOOK_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_INSTAGRAM :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_INSTAGRAM_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_TWITTER :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_TWITTER_BASE_URL}${URL}`;
                break;
            case constants.SOCIAL_MEDIA_TYPE_WEBSITE :
                dURL = `${constants.SOCIAL_MEDIA_TYPE_WEBSITE_BASE_URL}${URL}`;
                break;
            default:
                break;
        }
        window.open(dURL, '_blank');
    };

    handleBlockEntity = async () => {
        this.setState({loading: true});

        await Promise.all(
            (await db.collection('stories')
                .where('pointOfInterest.id', '==', this.state.entity.id)
                .where('status', '==', constants.ENTITY_STATUS_ACTIVE)
                .get())
                .docs.map(doc => {
                doc.ref.set({pointOfInterest: {status: constants.ENTITY_STATUS_BLOCKED}}, {merge: true})
                return doc.id;
            })
        );
        await DB.update(`pois`, new DBParams(this.state.entity.id, {status: constants.ENTITY_STATUS_BLOCKED}))

        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    }

    handleUnblockEntity = async () => {
        this.setState({loading: true});
        await Promise.all(
            (await db.collection('stories')
                .where('pointOfInterest.id', '==', this.state.entity.id)
                .where('status', '==', constants.ENTITY_STATUS_BLOCKED)
                .get())
                .docs
                .map(async doc => {
                    await doc.ref.set({
                        pointOfInterest: {status: constants.ENTITY_STATUS_ACTIVE},
                        status: constants.ENTITY_STATUS_ACTIVE,
                    }, {merge: true})
                    return doc.id;
                })
        );
        await DB.update(`pois`, new DBParams(this.state.entity.id, {status: constants.ENTITY_STATUS_ACTIVE}))

        this.handleCloseBlockDialog();
        this.props.handleCloseEntityDialog();
    };

    handleResolveReport = () => {
        if (this.props.handleResolveReport !== null && this.props.handleResolveReport !== undefined) {
            this.props.handleResolveReport();
        }
    }

    handleCloseBlockDialog = () => {
        this.handleResolveReport();
    }

    render() {

        const {classes, origin} = this.props;
        const {loading, cardExpanded, anchorElement, entity, entityStatusChangeInProgress} = this.state;

        return (
            <React.Fragment>
                {loading || !entity ? <Loader/> : (
                    <React.Fragment>
                        <Card className={classes.card}>
                            <CardHeader
                                action={
                                    <Button
                                        aria-owns={entity ? 'simple-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleOpenEntityMenu}
                                    >
                                        <MoreVert/>
                                    </Button>
                                }
                                title={entity.name && entity.name.length > 20 ? `${entity.name.substring(0, 20)}...` : entity.name}
                                subheader={`tag: ${entity.tag}`}
                            />
                            <CardMedia
                                className={clsx(classes.media, classes.avatarImage)}
                                image={entity.avatar && entity.avatar.url}
                                title="Avatar"
                            />


                            <CardActions>

                                {entity.status === constants.LOCATION_STATUS_BLOCKED &&
                                <Chip
                                    avatar={<Avatar><BlockIcon/></Avatar>}
                                    label="Blocked"
                                    color="secondary"
                                    className={classes.chip}
                                />
                                }
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: cardExpanded,
                                    })}
                                    onClick={this.handleExpandClick}
                                    aria-expanded={cardExpanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </CardActions>
                            <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {entity.description && <Typography paragraph>
                                        Description: <span className={classes.propertyValue}>{entity.description}</span>
                                    </Typography>}
                                </CardContent>
                                {entity.socialMedias && (
                                    <CardContent>
                                        {entity.socialMedias.length > 0 && (
                                            <Typography variant={'h6'}>Social Medias</Typography>
                                        )}
                                        {entity.socialMedias.map((socialMedia, index) => (
                                            <IconButton
                                                className={clsx(classes[`socialMediaIconButton`])}
                                                key={index}
                                                onClick={this.handleOpenSocialMediaLink(socialMedia.link.url, socialMedia.type)}
                                            >
                                                <img src={logos[socialMedia.type]} alt={socialMedia.type}/>
                                            </IconButton>
                                        ))}
                                    </CardContent>
                                )}
                            </Collapse>
                        </Card>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REGULAR && !entityStatusChangeInProgress && entity && (
                                <MenuItem
                                    onClick={entity.status === constants.LOCATION_STATUS_ACTIVE ? this.handleBlockEntity : this.handleUnblockEntity}
                                >
                                    {entity.status === constants.LOCATION_STATUS_ACTIVE ? 'Block' : 'Unblock'}
                                </MenuItem>
                            )}
                            {origin === constants.ONE_ENTITY_COMPONENT_TYPE_REPORT && !entityStatusChangeInProgress && entity && [
                                (<MenuItem key={'blockKey'} onClick={this.handleBlockEntity}>Block</MenuItem>),
                                (<MenuItem key={'sanctionKey'}
                                           onClick={this.props.handleSanctionEntity}>Sanction</MenuItem>),
                            ]}
                        </Menu>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={this.props.handleCloseEntityDialog}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

OnePoi.propTypes = {
    classes: PropTypes.object.isRequired,
    entityId: PropTypes.string.isRequired,
    handleCloseEntityDialog: PropTypes.func.isRequired,
    handleResolveReport: PropTypes.func,
    handleSanctionEntity: PropTypes.func,
    origin: PropTypes.string.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(OnePoi))