import React from "react";
import ReportedContent from 'components/admin/ReportedContent';

class ReportedPersons extends React.Component {

    render() {
        return <ReportedContent collectionName={'persons'}/>
    }
}

export default ReportedPersons