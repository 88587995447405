import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import StaticsServiceLocalization from 'common/StaticsServiceLocalization';
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {db} from 'common/firebase';
import DialogContentText from "@material-ui/core/DialogContentText";
import * as _ from 'lodash';

class Localization extends React.Component {

    localizationService;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            originalStaticsData: {},
            staticsData: {},
            version: null,
            editDialogOpen: false,
            publishDialogOpen: false,
            entityKey: '',
            entityLanguage: '',
            tmpTextEditValue: '',
            entityText: {
                en_GB: '',
                de_DE: '',
            },
            changes: {},
        };
    }

    get statics() {
        return Object.entries(this.state.staticsData).map(staticsDatum => {
            return {
                key: staticsDatum[0],
                en_GB: {
                    edited: this.state.originalStaticsData[staticsDatum[0]]['en_GB'] !== staticsDatum[1]['en_GB'],
                    value: staticsDatum[1]['en_GB']
                },
                de_DE: {
                    edited: this.state.originalStaticsData[staticsDatum[0]]['de_DE'] !== staticsDatum[1]['de_DE'],
                    value: staticsDatum[1]['de_DE']
                },
                // for data searching
                en_GB_live: staticsDatum[1]['en_GB'],
                de_DE_live: staticsDatum[1]['de_DE'],
            }
        });
    }

    get publishDisabled() {
        return Object.entries(this.state.changes).length < 1;
    }

    get saveLocallyDisabled() {
        return this.state.tmpTextEditValue === this.state.entityText[this.state.entityLanguage];
    }

    get revertLocallyDisabled() {
        if (
            Object.entries(this.state.originalStaticsData).length < 1
            || Object.entries(this.state.staticsData).length < 1
            || this.state.entityKey.length < 1
            || this.state.entityLanguage.length < 1
        ) {
            return true;
        }
        return this.state.staticsData[this.state.entityKey][this.state.entityLanguage] === this.state.originalStaticsData[this.state.entityKey][this.state.entityLanguage];
    }

    componentDidMount() {

        this.fetchData()
            .then(async () => {
                await this.setState({
                    loading: false,
                })
            })
    }

    async fetchData() {

        this.localizationService = new StaticsServiceLocalization();
        this.localizationService.fetchData()
            .then(async res => {
                const data = this.localizationService.toJSON()['localization'];
                await this.setState({
                    version: this.localizationService.version,
                    originalStaticsData: data,
                    staticsData: _.cloneDeep(data),
                })
            })
    }

    handleOpenEditDialog = (language) => async () => {
        this.setState({
            entityLanguage: language,
            editDialogOpen: true,
        })
    };

    handleCloseEditDialog = async () => {
        await this.setState({
            editDialogOpen: false,
            entityText: {
                en_GB: 'en-closed',
                de_DE: 'de-closed',
            },
            tmpTextEditValue: '',
            entityLanguage: '',
            entityKey: '',
        })
    };

    handleTextFieldChange = async event => {
        await this.setState({tmpTextEditValue: event.target.value});
    };

    handleResetChanges = async () => {
        await this.fetchData();
        await this.setState({changes: []});
    };

    handleSaveOneChangeLocally = async () => {
        const entityValue = this.state.staticsData[this.state.entityKey];
        entityValue[this.state.entityLanguage] = this.state.tmpTextEditValue;
        this.state.changes[this.state.entityKey] = entityValue;

        // save in local state to show in table until publish to DB
        this.handleCloseEditDialog();
        const staticsData = this.state.staticsData;
        staticsData[this.state.entityKey] = entityValue;
        await this.setState({staticsData: staticsData});
    };

    handleRevertOneChangeLocally = async () => {
        const tmpValue = this.state.originalStaticsData[this.state.entityKey][this.state.entityLanguage];
        this.state.staticsData[this.state.entityKey][this.state.entityLanguage] = tmpValue;
        this.state.entityText[this.state.entityLanguage] = tmpValue;
        const tmpChanges = _.omit(this.state.changes, [this.state.entityKey]);

        await this.setState({
            tmpTextEditValue: tmpValue,
            changes: tmpChanges,
        });
    };

    handleSaveAllChangesToDB = async () => {
        await this.setState({loading: true});
        const localizationDoc = await db.collection('statics').doc('localization').get();
        const newVersion = localizationDoc.data()._version + 1;
        await localizationDoc.ref.set({
            _version: newVersion,
            ...this.state.changes,
        }, {merge: true})
            .then(async res => {
                await this.handleResetChanges();
                this.handleCloseConfirmPublishDialog();
                this.setState({loading: false});
            })
            .catch(e => {
                console.error('E0032', e);
            });
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            entityKey: rowData[1],
            entityText: {
                en_GB: rowData[2].props.entity.value,
                de_DE: rowData[3].props.entity.value,
            },
        });
        await this.setState({
            tmpTextEditValue: this.state.entityText[this.state.entityLanguage],
        });
    };

    handleCloseConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: false});
    };

    handleOpenConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: true});
    };

    render() {

        const {classes} = this.props;
        const {
            loading, version, editDialogOpen, working, entityKey, entityLanguage,
            tmpTextEditValue, publishDialogOpen,
        } = this.state;

        if (loading) {
            return <Loader/>
        }

        const options = {
            responsive: "stacked",
            selectableRows: 'none',
            rowHover: false,
            elevation: 1,
            filter: false,
            filterType: 'dropdown',
            print: false,
            download: false,
            search: true,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 15, 100],
            textLabels: {
                body: {
                    noMatch:
                        'Sorry, there is no matching data to display',
                },
            },
            onRowClick: this.handleRowClick,
        };

        const columns = [
            {
                label: "Language Edit",
                name: "actions",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                    customBodyRender: (value) => {
                        return (
                            <React.Fragment>
                                <div className={classes.actionsContainer}>
                                    <Tooltip title='Edit English Text'>
                                        <Button
                                            className={classes.languageButton}
                                            aria-label="Edit-EN"
                                            size="small"
                                            variant={"contained"}
                                            onClick={this.handleOpenEditDialog('en_GB')}
                                        >
                                            EN
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Edit German Text'>
                                        <Button
                                            className={classes.languageButton}
                                            aria-label="Edit-DE"
                                            size="small"
                                            variant={"contained"}
                                            onClick={this.handleOpenEditDialog('de_DE')}
                                        >
                                            DE
                                        </Button>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        )
                    },
                },
            },
            {
                label: "Key",
                name: "key",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                label: "English",
                name: "en_GB",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <div entity={value}>
                            {value.edited ? (
                                <Tooltip title={'Value Changed'}>
                                    <span
                                        onClick={this.handleOpenEditDialog('en_GB')}
                                    >
                                        <input
                                            className={classes.editedValue}
                                            type={'text'}
                                            value={value.value}
                                            disabled={true}
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title={'Click/Tap to Edit'}>
                                    <span
                                        onClick={this.handleOpenEditDialog('en_GB')}
                                    >
                                        <input
                                            className={classes.vanillaValue}
                                            type={'text'}
                                            value={value.value}
                                            disabled={true}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    )
                }
            },
            {
                label: "German",
                name: "de_DE",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <div entity={value}>
                            {value.edited ? (
                                <Tooltip title={'Value Changed'}>
                                    <span
                                        onClick={this.handleOpenEditDialog('de_DE')}
                                    >
                                        <input
                                            className={classes.editedValue}
                                            type={'text'}
                                            value={value.value}
                                            disabled={true}
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title={'Click/Tap to Edit'}>
                                    <span
                                        onClick={this.handleOpenEditDialog('de_DE')}
                                    >
                                        <input
                                            className={classes.vanillaValue}
                                            type={'text'}
                                            value={value.value}
                                            disabled={true}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    )
                }
            },
            {
                name: "en_GB_live",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "de_DE_live",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
        ];

        return (
            <React.Fragment>
                {version && (
                    <Typography className={classes.versionLabel}>version {version}</Typography>
                )}
                <MUIDataTable
                    title={"Foods"}
                    data={this.statics}
                    columns={columns}
                    options={{...options,}}
                />
                <span style={{color: 'red'}}>* Value Changed</span>
                <Button
                    className={classes.fabPublish}
                    color={"primary"}
                    variant={'contained'}
                    onClick={this.handleOpenConfirmPublishDialog}
                    disabled={this.publishDisabled}
                >
                    <Typography style={{color: "white"}}>Publish</Typography>
                </Button>
                <Button
                    className={classes.fabDiscard}
                    color={"secondary"}
                    variant={'contained'}
                    onClick={this.handleResetChanges}
                    disabled={this.publishDisabled}
                >
                    <Typography style={{color: "white"}}>Discard Changes</Typography>
                </Button>
                <Dialog
                    open={editDialogOpen}
                    onClose={this.handleCloseEditDialog}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    {working ? (
                        <Loader/>
                    ) : (
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">Entity: <strong>{entityKey}</strong>,
                                Language: <strong>{entityLanguage}</strong></DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="entityTextField"
                                    variant={"outlined"}
                                    multiline={true}
                                    fullWidth
                                    value={tmpTextEditValue}
                                    onChange={this.handleTextFieldChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleRevertOneChangeLocally}
                                    variant={'contained'}
                                    disabled={this.revertLocallyDisabled}
                                >
                                    Revert
                                </Button>
                                <Button
                                    onClick={this.handleSaveOneChangeLocally}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{color: 'white'}}
                                    disabled={this.saveLocallyDisabled}
                                >
                                    Save
                                </Button>
                                <Button
                                    color={'secondary'}
                                    variant={'contained'}
                                    onClick={this.handleCloseEditDialog}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>
                <Dialog
                    open={publishDialogOpen}
                    onClose={this.handleSaveAllChangesToDB}
                    aria-labelledby="responsive-dialog-title"
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle id="responsive-dialog-title">{"Confirm Publish"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will publish changes to mobile App.<br/>
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleSaveAllChangesToDB}
                            color={'primary'}
                            variant={'contained'}
                            style={{color: "white"}}
                            autoFocus
                        >
                            Confirm
                        </Button>
                        <Button
                            onClick={this.handleCloseConfirmPublishDialog}
                            color={'secondary'}
                            variant={'contained'}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

Localization.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Localization)