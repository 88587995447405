import React from 'react';
import Dashboard from './Dashboard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FlagIcon from '@material-ui/icons/Flag';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FormatClearIcon from '@material-ui/icons/FormatClear';
import TranslateIcon from '@material-ui/icons/Translate';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import Employees from "./Employees";
import Persons from "./Persons";
import Stories from "./Stories";
import Activities from "./Activities";
import Locations from "./Locations";
import Words from "./Words";
import Images from './Images';
import ReportedPersons from './ReportedPersons';
import ReportedStories from './ReportedStories';
import ReportedLocations from './ReportedLocations';
import ReportedActivities from './ReportedActivities';
import FlaggedStories from "./FlaggedStories";
import Localization from "./Localization";
import Imprints from "./Imprints";

export const menus = [
    {
        entries: [{
            primary: "Dashboard",
            icon: <DashboardIcon/>,
            component: <Dashboard/>
        },]
    },
    {
        title: "Content",
        entries: [{
            primary: "Persons",
            icon: <PermIdentityIcon/>,
            component: <Persons/>
        },
            {
                primary: "Stories",
                icon: <LoyaltyIcon/>,
                component: <Stories/>
            },
            {
                primary: "Activities",
                icon: <TrendingUpIcon/>,
                component: <Activities/>
            },
            {
                primary: "Locations",
                icon: <LocationOnIcon/>,
                component: <Locations/>
            },
        ],
    },
    {
        title: "Masterdata",
        entries: [
            {
                primary: "Localization",
                icon: <TranslateIcon/>,
                component: <Localization/>
            },
            {
                primary: "Imprints",
                icon: <TranslateIcon/>,
                component: <Imprints/>
            },
            {
                primary: "Unwanted Words",
                icon: <FormatClearIcon/>,
                component: <Words/>
            },
            {
                primary: "Unwanted Images",
                icon: <BlurOffIcon/>,
                component: <Images/>
            },
        ],
    },
    {
        title: "Compliance",
        entries: [
            {
                primary: "Reported Persons",
                icon: <PermIdentityIcon/>,
                component: <ReportedPersons/>
            },
            {
                primary: "Flagged Stories",
                icon: <FlagIcon/>,
                component: <FlaggedStories/>
            },
            {
                primary: "Reported Stories",
                icon: <LoyaltyIcon/>,
                component: <ReportedStories/>
            },
            {
                primary: "Reported Activities",
                icon: <TrendingUpIcon/>,
                component: <ReportedActivities/>
            },
            {
                primary: "Reported Locations",
                icon: <LocationOnIcon/>,
                component: <ReportedLocations/>
            },
        ]
    },
    {
        title: "Organization",
        adminOnly: true,
        entries: [
            {
                primary: "Employee",
                icon: <AccessibilityIcon/>,
                component: <Employees/>,
                adminOnly: true,
            }
        ]
    },
];