class DBParams {

    constructor(doc = null, data = {
        whereConditions: [],
        orderBy: null,
        orderDirection: null,
    }) {
        this._doc = doc;
        this._data = data;
    }


    get doc() {
        return this._doc;
    }

    set doc(value) {
        this._doc = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }
}

export default DBParams;


export class WhereCondition {

    constructor(property, comparator, value) {
        this._property = property;
        this._comparator = comparator;
        this._value = value;
    }

    get property() {
        return this._property;
    }

    set property(value) {
        this._property = value;
    }

    get comparator() {
        return this._comparator;
    }

    set comparator(value) {
        this._comparator = value;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }
}
