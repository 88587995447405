import React from "react";
import PropTypes from "prop-types";
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import myTheme from 'common/theme';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({});

class InfoDialog extends React.Component {

    get title() {
        return this.props.title ? this.props.title : 'Info';
    }

    get message() {
        return this.props.message ? this.props.message : 'Message text missing!!';
    }

    handleClose = () => {
        this.props.handleClose();
    }

    render() {

        const {open} = this.props;

        return (
            <MuiThemeProvider theme={myTheme}>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

InfoDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(InfoDialog)