import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        fab: {
            position: "fixed",
            right: '20px',
            bottom: '20px',
        },
        textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
        },
        img: {
            height: '100%',
            width: '100%',
            position: 'absolute',
        },
        dropzone: {
            position: 'relative',
            width: '200px',
            height: '200px',
            borderWidth: '2px',
            borderColor: 'rgb(102, 102, 102)',
            borderStyle: 'dashed',
            borderRadius: '5px',
        },
        dropzoneText: {
            margin: '20%',
        },
    }
});
