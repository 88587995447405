import React, { Component } from 'react';

class Page404 extends Component {
    render() {
        return (
            <div>not found</div>
        )
    }
}

export default Page404