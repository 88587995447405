import red from '@material-ui/core/colors/red';
import myTheme from "common/theme";

export default theme => ({
    ...myTheme,
    ...{
        root: {
            minWidth: '80vw',
        },
        fab: {
            position: "absolute",
            right: '20px',
            bottom: '20px',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        avatarImage: {
            backgroundSize: 'contain',
        },
        socialMediaGroupIcons: {
            display: "flex",
        },
        propertyValue: {
            fontWeight: "bold",
        },
        closeButton: {
            position: 'fixed',
            right: theme.spacing.unit,
            top: theme.spacing.unit,
            color: theme.palette.grey[500],
        },
    }
});
