import red from '@material-ui/core/colors/red';
import myTheme from 'common/theme';

export default theme => ({
    ...myTheme,
    ...{
        card: {
            maxWidth: 400,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        avatarImage: {
            backgroundSize: 'contain',
        },
        theOneEntityDialog: {
            minWidth: '444px',
        },
        tableLoader: {
            position: 'absolute',
            left: '50%',
            top: '50%',
        },
        tableFetchingDataOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            background: `rgba(0, 180, 219, 0.05)`,
            zIndex: 1000,
            display: 'block',
        },
    },
});
