// import adminTheme from "common/theme";

export default theme => ({
    // ...adminTheme,
    ...{
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
        versionLabel: {
            textAlign: 'right',
        },
        languageButton: {
            marginRight: theme.spacing.unit * 2,
        },
        fabDiscard: {
            position: 'fixed',
            bottom: theme.spacing.unit * 2,
            right: theme.spacing.unit * 2,
        },
        fabPublish: {
            position: 'fixed',
            bottom: theme.spacing.unit * 2,
            right: theme.spacing.unit * 25,
        },
        editedValue: {
            color: theme.palette.secondary.main,
            // fontWeight: 400,
            // color: theme.palette.secondary.main,
            // fontWeight: 400,
            padding: theme.spacing.unit * 1,
            fontSize: '1.1em',
            border: `1px solid`,
            borderColor: theme.palette.secondary.main,
            '&:hover': {
                // background: "#f00",
                cursor: 'pointer',
            },
        },
        vanillaValue: {
            // color: theme.palette.secondary.main,
            // fontWeight: 400,
            padding: theme.spacing.unit * 1,
            fontSize: '1.1em',
            border: `1px solid`,
            borderColor: theme.palette.primary.main,
            '&:hover': {
                // background: "#f00",
                cursor: 'pointer',
            },
        },
    }
});
