import React from 'react';
import {Typography, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {AdminContextConsumer} from "pages/Admin";
import {menus} from "./Menus";
import uuid from "uuid/v1"
import {connect} from "react-redux";
import * as constants from "common/constants";
import * as utils from "common/utils";
import Loader from "components/Loader";
import Version from "components/Version";

const styles = theme => ({
    listItemHighlight: {
        backgroundColor: '#00B4DB',
    },
    listItemIcon: {
        color: 'white',
    },
    listItemText: {
        color: 'white',
    },
});

class DrawerMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isAdmin: false,
            currentPage: 'Dashboard',
            userAccessLevel: null,
        };
    }

    componentDidMount() {
        this.props.user.getIdTokenResult()
            .then(async (idTokenResult) => {
                // Confirm the user is an Admin.
                await this.setState({
                    userAccessLevel: idTokenResult.claims.accessLevel,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleListItemClick = async (entry) => {
        await this.setState({currentPage: entry.primary});
    }

    render() {

        const {classes} = this.props;
        const {currentPage, loading} = this.state;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({drawerOpen, onMenuChange}) => (
                        <React.Fragment>
                            {loading ? (<Loader />) : (
                                <React.Fragment>
                                    {menus
                                        .filter(entry => !(entry.adminOnly && this.state.userAccessLevel !== constants.USER_ACCESS_LEVEL_MANAGER))
                                        .map(menu => {

                                        return (
                                            <div key={uuid()}>
                                                {utils.notNullOrUndefined(menu.title) && drawerOpen ? (
                                                    <ListSubheader disableSticky={true}>{menu.title}</ListSubheader>
                                                ) : (utils.notNullOrUndefined(menu.title) && !drawerOpen && <Divider/>)}
                                                {
                                                    menu.entries
                                                        .filter(entry => !(entry.adminOnly && this.state.userAccessLevel !== constants.USER_ACCESS_LEVEL_MANAGER))
                                                        .map(entry => {
                                                            return (
                                                                <ListItem
                                                                    button
                                                                    onClick={async e => {
                                                                        await this.handleListItemClick(entry);
                                                                        return onMenuChange(entry);
                                                                    }}
                                                                    key={entry.primary}
                                                                    className={currentPage === entry.primary ? classes.listItemHighlight : ''}
                                                                >
                                                                    <Tooltip
                                                                        title={utils.notNullOrUndefined(entry.tooltip) ? entry.tooltip : entry.primary}>
                                                                        <ListItemIcon
                                                                            className={currentPage === entry.primary ? classes.listItemIcon : ''}
                                                                        >
                                                                            {entry.icon}
                                                                        </ListItemIcon>
                                                                    </Tooltip>
                                                                    <ListItemText
                                                                        primary={(<Typography
                                                                            className={currentPage === entry.primary ? classes.listItemText : ''}
                                                                        >
                                                                            {entry.primary}
                                                                        </Typography>)}
                                                                    />
                                                                </ListItem>
                                                            )
                                                        })
                                                }
                                            </div>
                                        )
                                    })}
                                    <Version />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        )
    }
}

DrawerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(DrawerMenu));
