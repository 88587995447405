import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from 'common/constants';
import styles from "./styles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone';
import DialogContentText from '@material-ui/core/DialogContentText';
import Jimp from "jimp";
import Typography from '@material-ui/core/Typography';
import DB from "common/DB";
import DBParams from "common/DBParams";
import StatusChip from "components/StatusChip";

class Images extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            entitiesData: [],
            anchorElement: null,
            theOneEntity: null,
            addDialogOpen: false,
            working: false,
            score: 0,
            comment: '',
            files: [],
            hash: '',
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get entities() {
        return this.state.entitiesData;
    }

    get hashExistsAlready() {
        return this.entities.filter(value => value.hash === this.state.hash).length > 0;
    }

    get isValidHash() {
        return this.state.hash.length > 0;
    }

    get isValidScore() {
        return !isNaN(this.state.score) && parseFloat(this.state.score) >= 0;
    }

    get isValidComment() {
        return this.state.comment.trim().length > 0;
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({loading: false});
            });
    }

    reloadTable = async () => {

        await DB.get(`imagesBlacklist`)
            .then(async result => {
                await this.setState({
                    entitiesData: result,
                });
            });
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            theOneEntity: {
                id: rowData[0],
                status: rowData[1],
            }
        });
    };

    handleOpenEntityMenu = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseEntityMenu = () => {
        this.setState({anchorElement: null});
    };

    handleBlockEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.update(`imagesBlacklist`, new DBParams(this.state.theOneEntity.id, {status: constants.IMAGE_STATUS_INACTIVE}))
        await this.reloadTable();
    };

    handleDeleteEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.delete(`imagesBlacklist`, new DBParams(this.state.theOneEntity.id));
        await this.reloadTable();
    };

    handleUnblockEntity = async () => {
        this.handleCloseEntityMenu();
        await DB.update(`imagesBlacklist`, new DBParams(this.state.theOneEntity.id, {status: constants.IMAGE_STATUS_ACTIVE}));
        await this.reloadTable();
    };

    handleOpenAddDialog = () => {
        this.setState({addDialogOpen: true});
    };

    handleCloseAddDialog = () => {
        this.setState({
            addDialogOpen: false
        });
        this.resetStateForNew();
    };

    resetStateForNew = () => {
        this.setState({
            score: 0,
            comment: '',
            files: [],
            hash: '',
            working: false,
        });
    };

    handleCancelNew = () => {
        this.handleCloseAddDialog();
    };

    handleSaveNew = async () => {
        this.setState({
            working: true,
        })

        await DB.add('imagesBlacklist', new DBParams(null, {
                comment: this.state.comment,
                hash: this.state.hash,
                score: parseInt(this.state.score),
                status: constants.IMAGE_STATUS_ACTIVE,
                group: 'uncategorised',
            },
        ))
            .then(async () => {
                await this.reloadTable();
                this.handleCloseAddDialog();
            })
    };

    handleTableChange = (action, tableState) => {
        if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    handleChange = event => {
        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    getColumns = () => {
        let columns = [
            {
                name: "id",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                name: "status",
                options: {
                    filter: false,
                    sort: false,
                    display: 'excluded',
                }
            },
            {
                label: "Score",
                name: "score",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Hash",
                name: "hash",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Comment",
                name: "comment",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Group",
                name: "group",
                options: {
                    filter: false,
                    sort: true,
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => value ? value : '-',
                },
            },
            {
                label: "Status",
                name: "status",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<StatusChip value={value}/>)
                    }
                },
            },
            {
                label: "Actions",
                name: 'id',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                                aria-owns={this.state.anchorElement ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleOpenEntityMenu}
                            >
                                <MoreVert/>
                            </Button>
                        )
                    }
                },
            },
        ];

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if(this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    render() {

        const {classes, fullScreen} = this.props;
        const {loading, anchorElement, theOneEntity, addDialogOpen, working, score, comment, files, hash} = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.entities}
                            columns={this.getColumns()}
                            options={{
                                filterType: 'checkbox',
                                responsive: "stacked",
                                selectableRows: 'none',
                                onRowClick: this.handleRowClick,
                                download: false,
                                print: false,
                                onTableChange: this.handleTableChange,
                                searchText: this.getSearchText(),
                            }}
                        />
                        <Fab className={classes.fab} color={"secondary"} onClick={this.handleOpenAddDialog}>
                            <AddIcon/>
                        </Fab>
                        <Dialog
                            fullScreen={fullScreen}
                            open={addDialogOpen}
                            onClose={this.handleCloseAddDialog}
                            aria-labelledby="responsive-dialog-title"
                            BackdropProps={{className: classes.backdrop}}
                        >
                            <DialogTitle
                                id="responsive-dialog-title">{"Add a new Image to Unwanted images"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>add a new image.</DialogContentText>
                                <Dropzone
                                    disabled={working}
                                    accept="image/*"
                                    onDrop={async acceptedFiles => {

                                        // useEffect(() => () => {
                                        //    // Make sure to revoke the data uris to avoid memory leaks
                                        // files.forEach(file => URL.revokeObjectURL(file.preview));
                                        // }, [files]);

                                        const ironedFiles = acceptedFiles.map(file => Object.assign(file, {
                                            content: file,
                                            preview: URL.createObjectURL(file)
                                        }));

                                        await this.setState({
                                            files: ironedFiles,
                                        });

                                        const reader = new FileReader();
                                        reader.onload = async () => {
                                            const fileAsBinary = reader.result;
                                            const image = await Jimp.read(fileAsBinary);
                                            this.setState({hash: image.pHash()});
                                        };
                                        reader.onabort = () => console.log('file reading was aborted');
                                        reader.onerror = () => console.log('file reading has failed');
                                        reader.readAsArrayBuffer(ironedFiles[0].content);

                                    }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div className={classes.dropzone} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {files.length > 0 ? (
                                                    <aside className={classes.thumbsContainer}>
                                                        {
                                                            files.map(file => (
                                                                <div className={classes.thumb} key={file.name}>
                                                                    <img
                                                                        alt={'a img'}
                                                                        src={file.preview}
                                                                        className={classes.img}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </aside>
                                                ) : (
                                                    <Typography className={classes.dropzoneText}>Drag 'n' drop some
                                                        files here, or click to select files</Typography>
                                                )}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <Typography>
                                    {hash}
                                </Typography>
                                <TextField
                                    value={comment}
                                    margin="dense"
                                    id="comment"
                                    name="comment"
                                    label="Comment"
                                    rowsMax={5}
                                    rows={5}
                                    multiline={true}
                                    type="text"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={working}
                                />

                                <TextField
                                    value={score}
                                    margin="dense"
                                    id="score"
                                    name="score"
                                    label="Score"
                                    error={!this.isValidScore}
                                    helperText={!this.isValidScore ? "Valid score is >= 0 " : ""}
                                    type="number"
                                    onChange={this.handleChange}
                                    fullWidth
                                    disabled={working}
                                />
                            </DialogContent>
                            {working ? (<Loader/>) : (
                                <DialogActions>
                                    <Button onClick={this.handleCancelNew} color="primary" disabled={working}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.handleSaveNew}
                                        color="primary"
                                        autoFocus
                                        disabled={working || !this.isValidComment || !this.isValidHash || !this.isValidScore || this.hashExistsAlready}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            )}
                        </Dialog>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseEntityMenu}
                        >
                            {theOneEntity && (
                                <MenuItem onClick={this.handleDeleteEntity}>Delete</MenuItem>
                            )}
                            {theOneEntity && theOneEntity.status === constants.IMAGE_STATUS_ACTIVE && (
                                <MenuItem onClick={this.handleBlockEntity}>Deactivate</MenuItem>
                            )}
                            {theOneEntity && theOneEntity.status === constants.IMAGE_STATUS_INACTIVE && (
                                <MenuItem onClick={this.handleUnblockEntity}>Activate</MenuItem>
                            )}
                        </Menu>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

Images.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

export default withStyles(styles, {withTheme: true})(withMobileDialog()(Images))