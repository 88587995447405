import React from "react";
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import Loader from "components/Loader"
import theme from "./theme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import styles from './styles';
import {
    // Budget,
    Users,
    Stories,
    SalesChart,
    Profit,
    // Progress,
} from './components';
import DB from "common/DB";
import * as utils from "common/utils";
import {db} from 'common/firebase';
import * as _ from 'lodash';
import moment from "moment";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            usersNow: [],
            usersUntilLastMonth: [],
            storiesNow: [],
            storiesUntilLastMonth: [],
            paymentStats: null,
        };
    }

    componentDidMount() {

        this.reload()
            .then(() => {
                this.setState({
                    loading: false
                });
            })
    }

    reload = async () => {
        // Users/Persons
        await DB.get('persons')
            .then(async result => await this.setState({usersNow: result}))

        const startAndEndOfMonth = utils.getStartAndEndOfMonth(moment().subtract(1, 'months'));
        await db.collection('persons')
            .where('created.timestamp', '<=', startAndEndOfMonth.dateTo)
            .get()
            .then(async result => {
                await this.setState({usersUntilLastMonth: result.docs.map(doc => utils.getDataWithIdFromFirebaseDocument(doc))})
            })

        // Stories
        await DB.get('stories')
            .then(async result => await this.setState({storiesNow: result}))

        await db.collection('stories')
            .where('date.timestamp', '<=', startAndEndOfMonth.dateTo)
            .get()
            .then(async result => {
                await this.setState({storiesUntilLastMonth: result.docs.map(doc => utils.getDataWithIdFromFirebaseDocument(doc))})
            })

        // Total profit
        await db.collection('statics')
            .doc('paymentData')
            .get()
            .then(res => this.setState({paymentStats: res.data()}));
    }

    getIncreaseFromAmountToAmount(fromAmount, toAmount) {
        const increase = _.round((fromAmount / toAmount) * 100 - 100);
        return increase === Infinity ? 100 : increase;
    }

    render() {

        const {classes} = this.props;
        const {loading, usersNow, usersUntilLastMonth, storiesNow, storiesUntilLastMonth, paymentStats} = this.state;

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <Grid
                                container
                                spacing={8}
                            >
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={6}
                                    xs={12}
                                >
                                    <Stories
                                        className={classes.item}
                                        data={{
                                            total: storiesNow.length,
                                            percentage: this.getIncreaseFromAmountToAmount(storiesNow.length, storiesUntilLastMonth.length),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={6}
                                    xs={12}
                                >
                                    <Users
                                        className={classes.item}
                                        data={{
                                            total: usersNow.length,
                                            percentage: this.getIncreaseFromAmountToAmount(usersNow.length, usersUntilLastMonth.length),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={6}
                                    xs={12}
                                >
                                    <Profit
                                        className={classes.item}
                                        paymentStats={paymentStats}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xl={12}
                                    xs={12}
                                >
                                    <SalesChart
                                        className={classes.item}
                                        paymentStats={paymentStats}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </MuiThemeProvider>
                )}
            </React.Fragment>
        )
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Dashboard)