import {createMuiTheme} from "@material-ui/core";

export default createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#00B4DB',
        },
    },
    backdrop: {
        backgroundColor: 'black',
        opacity: '.8 !important',
    },
});
